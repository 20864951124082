import logo from './logo.svg';
import './App.css';
import * as React from "react";
import Home from './Components/Home/Home';
import About from './Components/AboutUS/About';
import Faq from './Components/Faq/Faq';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Policies from './Components/policy/Policies';
import Terms from './Components/Terms/Terms';



function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/faq' element={<Faq />} />
          <Route path='/policy' element={<Policies />} />
          <Route path='/terms' element={<Terms />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
