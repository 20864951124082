import React from 'react'

const About = () => {
  return (
    <body class="page-template-default page page-id-25954 wp-custom-logo theme-spin hide_fixed_rows_enabled woocommerce-no-js tribe-no-js tinvwl-theme-style skin_default woo_extensions_present scheme_default blog_mode_page body_style_wide is_single sidebar_hide expand_content trx_addons_present header_type_custom header_style_header-custom-4614 header_position_default menu_side_none no_layout fixed_blocks_sticky elementor-default elementor-kit-15 elementor-page elementor-page-25954">

      <div className="body_wrap">
        <div className="page_wrap">
          <a
            className="spin_skip_link skip_to_content_link"
            href="#content_skip_link_anchor"
            tabIndex={1}
          >
            Skip to content
          </a>
          <a
            className="spin_skip_link skip_to_footer_link"
            href="#footer_skip_link_anchor"
            tabIndex={1}
          >
            Skip to footer
          </a>
          <header className="top_panel top_panel_custom top_panel_custom_4614 top_panel_custom_header-main-spin				 without_bg_image scheme_dark">
            <div
              data-elementor-type="cpt_layouts"
              data-elementor-id={4614}
              className="elementor elementor-4614"
            >
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-049a6f2 elementor-section-full_width elementor-section-content-middle sc_layouts_row sc_layouts_row_type_compact scheme_dark sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile elementor-section-height-default elementor-section-height-default sc_fly_static"
                data-id="049a6f2"
                data-element_type="section"
                data-settings='{"background_background":"classic"}'
              >
                <div className="elementor-container elementor-column-gap-extended">
                  <div
                    className="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-6958980 sc_layouts_column_align_left sc_layouts_column sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                    data-id={6958980}
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="sc_layouts_item elementor-element elementor-element-5eb54b3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_logo"
                        data-id="5eb54b3"
                        data-element_type="widget"
                        data-widget_type="trx_sc_layouts_logo.default"
                      >
                        <div className="elementor-widget-container">
                          <a
                            href="https://spin.axiomthemes.com/"
                            className="sc_layouts_logo sc_layouts_logo_default trx_addons_inline_1621724556"
                          >
                            <img
                              className="logo_image"
                              src="//spin.axiomthemes.com/wp-content/uploads/2023/07/new-logo.png"
                              srcSet="//spin.axiomthemes.com/wp-content/uploads/2023/07/new-logo-2x.png 2x"
                              alt="Spin"
                              width={108}
                              height={80}
                            />
                          </a>{" "}
                        </div>
                      </div>
                      <div
                        className="sc_layouts_item elementor-element elementor-element-64c83016 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_menu"
                        data-id="64c83016"
                        data-element_type="widget"
                        data-widget_type="trx_sc_layouts_menu.default"
                      >
                        <div className="elementor-widget-container">
                          <nav
                            className="sc_layouts_menu sc_layouts_menu_default sc_layouts_menu_dir_horizontal menu_hover_zoom_line"
                            data-animation-in="none"
                            data-animation-out="none"
                          >
                            <ul
                              id="sc_layouts_menu_1593324209"
                              className="sc_layouts_menu_nav"
                            >
                              <li
                                id="menu-item-26257"
                                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-26257"
                              >
                                <a href="/">
                                  <span>Home</span>
                                </a>
                              </li>
                              <li
                                id="menu-item-25953"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-25953"
                              >
                                <a href="/about">
                                  <span>About</span>
                                </a>
                              </li>
                              <li
                                id="menu-item-28034"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-28034"
                              >
                                <a href="/policy">
                                  <span>Policy</span>
                                </a>
                              </li>
                              <li
                                id="menu-item-26084"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-26084"
                              >
                                <a href="/terms">
                                  <span>Terms</span>
                                </a>
                              </li>
                            </ul>
                          </nav>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-f4f639d sc_layouts_column_align_right sc_layouts_column sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                    data-id="f4f639d"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="sc_layouts_item elementor-element elementor-element-d45aa43 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_cart"
                        data-id="d45aa43"
                        data-element_type="widget"
                        data-widget_type="trx_sc_layouts_cart.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="sc_layouts_cart sc_layouts_cart_market_woocommerce">
                            <span className="sc_layouts_item_icon sc_layouts_cart_icon sc_icons_type_icons trx_addons_icon-basket" />
                            <span className="sc_layouts_item_details sc_layouts_cart_details">
                              <span className="sc_layouts_item_details_line2 sc_layouts_cart_totals">
                                <span
                                  className="sc_layouts_cart_items"
                                  data-item="item"
                                  data-items="items"
                                >
                                  0 items
                                </span>
                                -
                                <span className="sc_layouts_cart_summa">$0.00</span>
                              </span>
                            </span>
                            <span className="sc_layouts_cart_items_short">0</span>
                            <div className="sc_layouts_cart_widget widget_area">
                              <span className="sc_layouts_cart_widget_close trx_addons_button_close">
                                <span className="sc_layouts_cart_widget_close_icon trx_addons_button_close_icon" />
                              </span>
                              <div className="widget woocommerce widget_shopping_cart">
                                <div className="widget_shopping_cart_content" />
                              </div>{" "}
                            </div>
                          </div>{" "}
                        </div>
                      </div>
                      <div
                        className="sc_layouts_item elementor-element elementor-element-57d98f2d sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_search"
                        data-id="57d98f2d"
                        data-element_type="widget"
                        data-widget_type="trx_sc_layouts_search.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="sc_layouts_search">
                            <div className="search_modern">
                              <span className="search_submit" />
                              <div className="search_wrap">
                                <div className="search_header_wrap">
                                  <img
                                    className="logo_image"
                                    src="//spin.axiomthemes.com/wp-content/uploads/2023/07/new-logo.png"
                                    srcSet="//spin.axiomthemes.com/wp-content/uploads/2023/07/new-logo-2x.png 2x"
                                    alt="Spin"
                                    width={108}
                                    height={80}
                                  />{" "}
                                  <a className="search_close" />
                                </div>
                                <div className="search_form_wrap">
                                  <form
                                    role="search"
                                    method="get"
                                    className="search_form"
                                    action="https://spin.axiomthemes.com/"
                                  >
                                    <input
                                      type="hidden"
                                      defaultValue=""
                                      name="post_types"
                                    />
                                    <input
                                      type="text"
                                      className="search_field"
                                      placeholder="Type words and hit enter"
                                      defaultValue=""
                                      name="s"
                                    />
                                    <button
                                      type="submit"
                                      className="search_submit"
                                    />
                                  </form>
                                </div>
                              </div>
                              <div className="search_overlay" />
                            </div>
                          </div>
                          {/* /.sc_layouts_search */}{" "}
                        </div>
                      </div>
                      <div
                        className="sc_layouts_item elementor-element elementor-element-2e04bb8 elementor-view-default sc_fly_static elementor-widget elementor-widget-icon"
                        data-id="2e04bb8"
                        data-element_type="widget"
                        data-widget_type="icon.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-icon-wrapper">
                            <a className="elementor-icon" href="#popup-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={21}
                                height={21}
                                viewBox="0 0 21 21"
                              >
                                <g
                                  className="right_bar"
                                  transform="translate(-2124 -2665)"
                                >
                                  <g
                                    transform="translate(2124 2665)"
                                    fill="none"
                                    strokeWidth="1.5"
                                  >
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="2.5"
                                      stroke="none"
                                    />
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="1.75"
                                      fill="none"
                                    />
                                  </g>
                                  <g
                                    transform="translate(2132 2665)"
                                    fill="none"
                                    strokeWidth="1.5"
                                  >
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="2.5"
                                      stroke="none"
                                    />
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="1.75"
                                      fill="none"
                                    />
                                  </g>
                                  <g
                                    transform="translate(2140 2665)"
                                    fill="none"
                                    strokeWidth="1.5"
                                  >
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="2.5"
                                      stroke="none"
                                    />
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="1.75"
                                      fill="none"
                                    />
                                  </g>
                                  <g
                                    transform="translate(2124 2673)"
                                    fill="none"
                                    strokeWidth="1.5"
                                  >
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="2.5"
                                      stroke="none"
                                    />
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="1.75"
                                      fill="none"
                                    />
                                  </g>
                                  <g
                                    transform="translate(2132 2673)"
                                    fill="none"
                                    strokeWidth="1.5"
                                  >
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="2.5"
                                      stroke="none"
                                    />
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="1.75"
                                      fill="none"
                                    />
                                  </g>
                                  <g
                                    transform="translate(2140 2673)"
                                    fill="none"
                                    strokeWidth="1.5"
                                  >
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="2.5"
                                      stroke="none"
                                    />
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="1.75"
                                      fill="none"
                                    />
                                  </g>
                                  <g
                                    transform="translate(2124 2681)"
                                    fill="none"
                                    strokeWidth="1.5"
                                  >
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="2.5"
                                      stroke="none"
                                    />
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="1.75"
                                      fill="none"
                                    />
                                  </g>
                                  <g
                                    transform="translate(2132 2681)"
                                    fill="none"
                                    strokeWidth="1.5"
                                  >
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="2.5"
                                      stroke="none"
                                    />
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="1.75"
                                      fill="none"
                                    />
                                  </g>
                                  <g
                                    transform="translate(2140 2681)"
                                    fill="none"
                                    strokeWidth="1.5"
                                  >
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="2.5"
                                      stroke="none"
                                    />
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="1.75"
                                      fill="none"
                                    />
                                  </g>
                                </g>
                              </svg>{" "}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-a531a8e elementor-section-content-middle sc_layouts_row sc_layouts_row_type_compact scheme_dark sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_layouts_hide_on_notebook elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                data-id="a531a8e"
                data-element_type="section"
                data-settings='{"background_background":"classic"}'
              >
                <div className="elementor-container elementor-column-gap-extended">
                  <div
                    className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-849781b sc_layouts_column_align_left sc_layouts_column sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                    data-id="849781b"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="sc_layouts_item elementor-element elementor-element-68f4b8d sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_logo"
                        data-id="68f4b8d"
                        data-element_type="widget"
                        data-widget_type="trx_sc_layouts_logo.default"
                      >
                        <div className="elementor-widget-container">
                          <a
                            href="https://spin.axiomthemes.com/"
                            className="sc_layouts_logo sc_layouts_logo_default trx_addons_inline_1161981657"
                          >
                            <img
                              className="logo_image"
                              src="//spin.axiomthemes.com/wp-content/uploads/2023/07/new-logo.png"
                              srcSet="//spin.axiomthemes.com/wp-content/uploads/2023/07/new-logo-2x.png 2x"
                              alt="Spin"
                              width={108}
                              height={80}
                            />
                          </a>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-06775e6 sc_layouts_column_align_right sc_layouts_column sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                    data-id="06775e6"
                    data-element_type="column"
                  >

                  </div>
                </div>
              </section>
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-d83ad90 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                data-id="d83ad90"
                data-element_type="section"
              >
                <div className="elementor-container elementor-column-gap-extended">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d282de1 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                    data-id="d282de1"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="sc_layouts_item elementor-element elementor-element-b42b660 sc_fly_static elementor-widget elementor-widget-spacer"
                        data-id="b42b660"
                        data-element_type="widget"
                        data-widget_type="spacer.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-spacer">
                            <div className="elementor-spacer-inner" />
                          </div>
                        </div>
                      </div>
                      <div
                        className="sc_layouts_item elementor-element elementor-element-246ccc7 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                        data-id="246ccc7"
                        data-element_type="widget"
                        data-widget_type="trx_sc_layouts_title.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="sc_layouts_title sc_align_center with_content without_image without_tint">
                            <div className="sc_layouts_title_content">
                              <div className="sc_layouts_title_title">
                                <h1 className="sc_layouts_title_caption">
                                  About
                                </h1>
                              </div>
                              <div className="sc_layouts_title_breadcrumbs">
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                      </div>
                      <div
                        className="sc_layouts_item elementor-element elementor-element-95bbfaf sc_fly_static elementor-widget elementor-widget-spacer"
                        data-id="95bbfaf"
                        data-element_type="widget"
                        data-widget_type="spacer.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-spacer">
                            <div className="elementor-spacer-inner" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </header>
          <div className="menu_mobile_overlay scheme_dark" />
          <div className="menu_mobile menu_mobile_fullscreen scheme_dark">
            <div className="menu_mobile_inner">
              <div className="menu_mobile_header_wrap">
                <a className="sc_layouts_logo" href="https://spin.axiomthemes.com/">
                  <img
                    src="//spin.axiomthemes.com/wp-content/uploads/2023/07/new-logo.png"
                    srcSet="//spin.axiomthemes.com/wp-content/uploads/2023/07/new-logo-2x.png 2x"
                    alt="Spin"
                    width={108}
                    height={80}
                  />{" "}
                </a>
                <a className="menu_mobile_close menu_button_close" tabIndex={0}>
                  <span className="menu_button_close_text">Close</span>
                  <span className="menu_button_close_icon" />
                </a>
              </div>
              <div className="menu_mobile_content_wrap content_wrap">
                <div className="menu_mobile_content_wrap_inner">
                  <nav
                    className="menu_mobile_nav_area"
                    itemScope="itemscope"
                    itemType="https://schema.org/SiteNavigationElement"
                  >
                    <ul id="menu_mobile_246449534">
                      <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-26257">
                        <a href="#">
                          <span>Home</span>
                        </a>
                        <ul className="sub-menu">
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-25985">
                            <a href="https://spin.axiomthemes.com/">
                              <span>Professional Club</span>
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-26255">
                            <a href="https://spin.axiomthemes.com/home-championship/">
                              <span>Championship</span>
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-27570">
                            <a href="https://spin.axiomthemes.com/home-academy/">
                              <span>Academy</span>
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-27358">
                            <a href="https://spin.axiomthemes.com/home-blog-news/">
                              <span>Blog &amp; News</span>
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-26982">
                            <a href="https://spin.axiomthemes.com/home-betting-livescore/">
                              <span>Betting &amp; Livescore</span>
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-26755">
                            <a href="https://spin.axiomthemes.com/home-online-store/">
                              <span>Online Store</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor menu-item-has-children menu-item-25953">
                        <a href="#">
                          <span>Pages</span>
                        </a>
                        <ul className="sub-menu">
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-26576">
                            <a href="https://spin.axiomthemes.com/about-us/">
                              <span>About Us</span>
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-26515">
                            <a href="https://spin.axiomthemes.com/our-league/">
                              <span>Our League</span>
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-26438">
                            <a href="https://spin.axiomthemes.com/our-school/">
                              <span>Our School</span>
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-26386">
                            <a href="https://spin.axiomthemes.com/our-team/">
                              <span>Our Team</span>
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-26331">
                            <a href="https://spin.axiomthemes.com/sponsors/">
                              <span>Sponsors</span>
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-26035">
                            <a href="https://spin.axiomthemes.com/faqs/">
                              <span>FAQ</span>
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-26018">
                            <a href="https://spin.axiomthemes.com/contacts/">
                              <span>Contacts</span>
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-25981">
                            <a href="#">
                              <span>Tools</span>
                            </a>
                            <ul className="sub-menu">
                              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-25984">
                                <a href="https://spin.axiomthemes.com/typography/">
                                  <span>Typography</span>
                                </a>
                              </li>
                              <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-25982">
                                <a href="/404">
                                  <span>404 Page</span>
                                </a>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-25954 current_page_item menu-item-25962">
                                <a
                                  href="https://spin.axiomthemes.com/service-plus/"
                                  aria-current="page"
                                >
                                  <span>Service Plus</span>
                                </a>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-25980">
                                <a href="https://spin.axiomthemes.com/newsletter-popup/">
                                  <span>Newsletter Popups</span>
                                </a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-28034">
                        <a href="#">
                          <span>SportsPress</span>
                        </a>
                        <ul className="sub-menu">
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-28060">
                            <a href="https://spin.axiomthemes.com/premier-league/">
                              <span>League</span>
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-post_type menu-item-object-sp_team menu-item-28061">
                            <a href="https://spin.axiomthemes.com/sport-team/spin/">
                              <span>Single Club</span>
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-post_type menu-item-object-sp_player menu-item-43056">
                            <a href="https://spin.axiomthemes.com/player/aiden-foster/">
                              <span>Single Player</span>
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-post_type menu-item-object-sp_event menu-item-28253">
                            <a href="https://spin.axiomthemes.com/sport-event/spin-alligator/">
                              <span>Single Match</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-26084">
                        <a href="#">
                          <span>Events</span>
                        </a>
                        <ul className="sub-menu">
                          <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-26085">
                            <a href="https://spin.axiomthemes.com/events/list/">
                              <span>Events List</span>
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-26086">
                            <a href="https://spin.axiomthemes.com/events/month/">
                              <span>Events Month</span>
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-post_type menu-item-object-tribe_events menu-item-26087">
                            <a href="https://spin.axiomthemes.com/event/junior-league-finals/">
                              <span>Single Event</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-26094">
                        <a href="#">
                          <span>Blog</span>
                        </a>
                        <ul className="sub-menu">
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-26095">
                            <a href="https://spin.axiomthemes.com/blog-standard/">
                              <span>Standard</span>
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-26096">
                            <a href="https://spin.axiomthemes.com/blog-list/">
                              <span>List</span>
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-26097">
                            <a href="https://spin.axiomthemes.com/blog-portfolio/">
                              <span>Portfolio</span>
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-26098">
                            <a href="#">
                              <span>Single Posts</span>
                            </a>
                            <ul className="sub-menu">
                              <li className="menu-item menu-item-type-post_type menu-item-object-post menu-item-42140">
                                <a href="https://spin.axiomthemes.com/the-art-of-playing-cricket-professionally/">
                                  <span>With Sidebar</span>
                                </a>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-post menu-item-42141">
                                <a href="https://spin.axiomthemes.com/why-did-australian-cricket-fans-get-mad/">
                                  <span>Without Sidebar</span>
                                </a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-26193">
                        <a href="#">
                          <span>Shop</span>
                        </a>
                        <ul className="sub-menu">
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-26194">
                            <a href="https://spin.axiomthemes.com/shop/">
                              <span>Product List</span>
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-43055">
                            <a href="https://spin.axiomthemes.com/product/cricket-helmet/">
                              <span>Product Single</span>
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-26196">
                            <a href="https://spin.axiomthemes.com/cart/">
                              <span>Cart</span>
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-26197">
                            <a href="https://spin.axiomthemes.com/checkout/">
                              <span>Checkout</span>
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-26198">
                            <a href="https://spin.axiomthemes.com/wishlist-page/">
                              <span>Wishlist Page</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </nav>
                  <div className="socials_mobile">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/AxiomThemes-505060569826537/"
                      className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"
                    >
                      <span
                        className="social_icon social_icon_facebook-1"
                        style={{}}
                      >
                        <span className="icon-facebook-1" />
                      </span>
                    </a>
                    <a
                      target="_blank"
                      href="https://twitter.com/ThemesAxiom/"
                      className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"
                    >
                      <span
                        className="social_icon social_icon_twitter-new"
                        style={{}}
                      >
                        <span className="icon-twitter-new" />
                      </span>
                    </a>
                    <a
                      target="_blank"
                      href="https://dribbble.com/axiomthemes/"
                      className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"
                    >
                      <span
                        className="social_icon social_icon_dribble-new"
                        style={{}}
                      >
                        <span className="icon-dribble-new" />
                      </span>
                    </a>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/axiom_themes/"
                      className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"
                    >
                      <span
                        className="social_icon social_icon_instagram"
                        style={{}}
                      >
                        <span className="icon-instagram" />
                      </span>
                    </a>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="page_content_wrap">
            <div className="content_wrap">
              <div className="content">
                <a
                  id="content_skip_link_anchor"
                  className="spin_skip_link_anchor"
                  href="#"
                />
                <article
                  id="post-25954"
                  className="post_item_single post_type_page post-25954 page type-page status-publish hentry"
                >
                  <div className="post_content entry-content">
                    <div
                      data-elementor-type="wp-page"
                      data-elementor-id={25954}
                      className="elementor elementor-25954"
                    >
                      <section
                        className="elementor-section elementor-top-section elementor-element elementor-element-7b945a18 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                        data-id="7b945a18"
                        data-element_type="section"
                      >
                        <div className="elementor-container elementor-column-gap-extended">
                          <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-20b3c34f sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-id="20b3c34f"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-699858ff sc_fly_static elementor-widget elementor-widget-text-editor"
                                data-id="699858ff"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container" style={{ textAlign: 'left' }}>
                                  <h1 style={{ fontSize: '1.5em', marginBottom: '0.5em' }}>About [Your App Name]</h1>

                                  <p>
                                    [Your App Name] is a leading platform dedicated to providing real-time cricket scores and the latest cricket news. Our mission is to deliver a seamless and enjoyable experience for cricket enthusiasts, keeping them informed and engaged with the world of cricket.
                                  </p>

                                  <h2 style={{ fontSize: '1.2em', marginBottom: '0.5em' }}>Our Vision</h2>

                                  <p>
                                    At [Your App Name], we envision creating a vibrant and dynamic community of cricket fans. We strive to be the go-to platform for anyone seeking up-to-date information on live cricket scores, match statistics, and breaking cricket news.
                                  </p>

                                  <h2 style={{ fontSize: '1.2em', marginBottom: '0.5em' }}>What We Offer</h2>

                                  <p>
                                    - <strong>Live Cricket Scores:</strong> Stay updated with real-time scores of ongoing cricket matches across various formats and tournaments.
                                    <br />
                                    - <strong>Latest Cricket News:</strong> Get the latest and most relevant cricket news, including match previews, player updates, and more.
                                    <br />
                                    - <strong>User-Friendly Experience:</strong> Our platform is designed to be user-friendly, ensuring a seamless and enjoyable experience for cricket enthusiasts of all levels.
                                  </p>

                                  <h2 style={{ fontSize: '1.2em', marginBottom: '0.5em' }}>Our Team</h2>

                                  <p>
                                    [Your App Name] is driven by a passionate and dedicated team of professionals who share a love for the game of cricket. Our developers, content creators, and support staff work together to provide a top-notch experience for our users.
                                  </p>

                                  <h2 style={{ fontSize: '1.2em', marginBottom: '0.5em' }}>Contact Us</h2>

                                  <p>
                                    Have questions or suggestions? We'd love to hear from you! Contact our support team at [Your Contact Information] or connect with us on [social media platforms].
                                  </p>

                                  <p>
                                    Thank you for choosing [Your App Name] as your cricket companion. We look forward to bringing you the excitement of cricket in real-time!
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                  {/* .entry-content */}
                </article>
              </div>
            </div>
          </div>
          <a
            id="footer_skip_link_anchor"
            className="spin_skip_link_anchor"
            href="#"
          />
          <footer className="footer_wrap footer_custom footer_custom_4105 footer_custom_footer-default-spin						 scheme_dark						">
            <div
              data-elementor-type="cpt_layouts"
              data-elementor-id={4105}
              className="elementor elementor-4105"
            >
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-6ebd55b elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                data-id="6ebd55b"
                data-element_type="section"
              >
                <div className="elementor-container elementor-column-gap-extended">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-65fe22a sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                    data-id="65fe22a"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="sc_layouts_item elementor-element elementor-element-f6c477d sc_fly_static elementor-widget elementor-widget-spacer"
                        data-id="f6c477d"
                        data-element_type="widget"
                        data-widget_type="spacer.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-spacer">
                            <div className="elementor-spacer-inner" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-ff2b8fa sc_layouts_row sc_layouts_row_type_compact elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                data-id="ff2b8fa"
                data-element_type="section"
              >
                <div className="elementor-container elementor-column-gap-extended">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d6fdcc0 sc_layouts_column_align_center sc_layouts_column sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                    data-id="d6fdcc0"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="sc_layouts_item elementor-element elementor-element-e961482 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_logo"
                        data-id="e961482"
                        data-element_type="widget"
                        data-widget_type="trx_sc_layouts_logo.default"
                      >
                        <div className="elementor-widget-container">
                          <a
                            href="https://spin.axiomthemes.com/"
                            className="sc_layouts_logo sc_layouts_logo_default trx_addons_inline_866222957"
                          >
                            <img
                              className="logo_image"
                              src="//spin.axiomthemes.com/wp-content/uploads/2023/07/new-logo.png"
                              srcSet="//spin.axiomthemes.com/wp-content/uploads/2023/07/new-logo-2x.png 2x"
                              alt="Spin"
                              width={108}
                              height={80}
                            />
                          </a>{" "}
                        </div>
                      </div>
                      <div
                        className="sc_layouts_item elementor-element elementor-element-74c7998 sc_fly_static elementor-widget elementor-widget-spacer"
                        data-id="74c7998"
                        data-element_type="widget"
                        data-widget_type="spacer.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-spacer">
                            <div className="elementor-spacer-inner" />
                          </div>
                        </div>
                      </div>
                      <div
                        className="sc_layouts_item elementor-element elementor-element-6624e35 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_menu"
                        data-id="6624e35"
                        data-element_type="widget"
                        data-widget_type="trx_sc_layouts_menu.default"
                      >
                        <div className="elementor-widget-container">
                          <nav
                            className="sc_layouts_menu sc_layouts_menu_default sc_layouts_menu_dir_horizontal menu_hover_zoom_line"
                            data-animation-in="none"
                            data-animation-out="none"
                          >
                            <ul
                              id="sc_layouts_menu_1593324209"
                              className="sc_layouts_menu_nav"
                            >
                              <li
                                id="menu-item-26257"
                                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-26257"
                              >
                                <a href="/">
                                  <span>Home</span>
                                </a>
                              </li>
                              <li
                                id="menu-item-25953"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-25953"
                              >
                                <a href="/about">
                                  <span>About</span>
                                </a>
                              </li>
                              <li
                                id="menu-item-28034"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-28034"
                              >
                                <a href="/policy">
                                  <span>Policy</span>
                                </a>
                              </li>
                              <li
                                id="menu-item-26084"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-26084"
                              >
                                <a href="/terms">
                                  <span>Terms</span>
                                </a>
                              </li>
                            </ul>
                          </nav>{" "}
                        </div>
                      </div>
                      <div
                        className="sc_layouts_item elementor-element elementor-element-21f0aea sc_fly_static elementor-widget elementor-widget-spacer"
                        data-id="21f0aea"
                        data-element_type="widget"
                        data-widget_type="spacer.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-spacer">
                            <div className="elementor-spacer-inner" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-988cdc8 sc_layouts_row sc_layouts_row_type_compact elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                data-id="988cdc8"
                data-element_type="section"
              >
                <div className="elementor-container elementor-column-gap-extended">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e77f160 sc_layouts_column_align_center sc_layouts_column sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                    data-id="e77f160"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="sc_layouts_item elementor-element elementor-element-a63dde5 sc_fly_static elementor-widget elementor-widget-spacer"
                        data-id="a63dde5"
                        data-element_type="widget"
                        data-widget_type="spacer.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-spacer">
                            <div className="elementor-spacer-inner" />
                          </div>
                        </div>
                      </div>
                      <div
                        className="sc_layouts_item elementor-element elementor-element-a50287f elementor-widget-divider--view-line sc_fly_static elementor-widget elementor-widget-divider"
                        data-id="a50287f"
                        data-element_type="widget"
                        data-widget_type="divider.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-divider">
                            <span className="elementor-divider-separator"></span>
                          </div>
                        </div>
                      </div>
                      <div
                        className="sc_layouts_item elementor-element elementor-element-2915a31 sc_fly_static elementor-widget elementor-widget-spacer"
                        data-id="2915a31"
                        data-element_type="widget"
                        data-widget_type="spacer.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-spacer">
                            <div className="elementor-spacer-inner" />
                          </div>
                        </div>
                      </div>
                      <div
                        className="sc_layouts_item elementor-element elementor-element-77a8943 sc_fly_static elementor-widget elementor-widget-text-editor"
                        data-id="77a8943"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div className="elementor-widget-container">
                          <p>
                            <a
                              href="https://themeforest.net/user/axiomthemes/portfolio"
                              target="_blank"
                              rel="noopener"
                            >
                              AxiomThemes
                            </a>{" "}
                            © 2024. All rights reserved.
                          </p>{" "}
                        </div>
                      </div>
                      <div
                        className="sc_layouts_item elementor-element elementor-element-b39a8d6 sc_fly_static elementor-widget elementor-widget-spacer"
                        data-id="b39a8d6"
                        data-element_type="widget"
                        data-widget_type="spacer.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-spacer">
                            <div className="elementor-spacer-inner" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </footer>
          {/* /.footer_wrap */}
        </div>
      </div>
      <div className="sc_layouts_panel_hide_content" />
      <div
        id="popup-1"
        className="sc_layouts scheme_dark sc_layouts_panel sc_layouts_4509 sc_layouts_panel_right sc_layouts_effect_slide trx_addons_inline_1522726263"
        data-delay={0}
        data-panel-position="right"
        data-panel-effect="slide"
        data-panel-class="trx_addons_inline_1522726263"
      >

      </div>
      <a
        href="#"
        className="trx_addons_scroll_to_top trx_addons_icon-up scroll_to_top_style_default"
        title="Scroll to top"
      />
      {/* Instagram Feed JS */}
      <div className="trx_addons_mouse_helper trx_addons_mouse_helper_base trx_addons_mouse_helper_style_default trx_addons_mouse_helper_smooth trx_addons_mouse_helper_centered" />
    </body>

  )
}

export default About