import React from 'react'

const Home = () => {
  return (
    <body className="home page-template-default page page-id-5002 wp-custom-logo theme-spin hide_fixed_rows_enabled frontpage woocommerce-no-js tribe-no-js tinvwl-theme-style skin_default woo_extensions_present scheme_default blog_mode_front body_style_fullscreen  is_stream blog_style_excerpt sidebar_hide expand_content remove_margins trx_addons_present header_type_custom header_style_header-custom-28016 header_position_over menu_side_none no_layout fixed_blocks_sticky elementor-default elementor-kit-15 elementor-page elementor-page-5002">
      <div className="body_wrap">
        <div className="page_wrap">
          <a
            className="spin_skip_link skip_to_content_link"
            href="#content_skip_link_anchor"
            tabIndex={1}
          >
            Skip to content
          </a>
          <a
            className="spin_skip_link skip_to_footer_link"
            href="#footer_skip_link_anchor"
            tabIndex={1}
          >
            Skip to footer
          </a>
          <header className="top_panel top_panel_custom top_panel_custom_28016 top_panel_custom_header-professional-club-spin				 without_bg_image scheme_dark">
            <div
              data-elementor-type="cpt_layouts"
              data-elementor-id={28016}
              className="elementor elementor-28016"
            >
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-78e7be0 elementor-section-full_width elementor-section-content-middle sc_layouts_row sc_layouts_row_type_compact sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile elementor-section-height-default elementor-section-height-default sc_fly_static"
                data-id="78e7be0"
                data-element_type="section"
              >
                <div className="elementor-container elementor-column-gap-extended">
                  <div
                    className="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-8d82240 sc_layouts_column_align_left sc_layouts_column sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                    data-id="8d82240"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="sc_layouts_item elementor-element elementor-element-1120d10 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_logo"
                        data-id="1120d10"
                        data-element_type="widget"
                        data-widget_type="trx_sc_layouts_logo.default"
                      >
                        <div className="elementor-widget-container">
                          <a
                            href="#"
                            className="sc_layouts_logo sc_layouts_logo_default trx_addons_inline_1054644673"
                          >
                            <img
                              loading="lazy"
                              className="logo_image"
                              src="//spin.axiomthemes.com/wp-content/uploads/2023/07/new-logo.png"
                              srcSet="//spin.axiomthemes.com/wp-content/uploads/2023/07/new-logo-2x.png 2x"
                              alt="Spin"
                              width={108}
                              height={80}
                            />
                          </a>{" "}
                        </div>
                      </div>
                      <div
                        className="sc_layouts_item elementor-element elementor-element-7c3df2e sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_menu"
                        data-id="7c3df2e"
                        data-element_type="widget"
                        data-widget_type="trx_sc_layouts_menu.default"
                      >
                        <div className="elementor-widget-container">
                          <nav
                            className="sc_layouts_menu sc_layouts_menu_default sc_layouts_menu_dir_horizontal menu_hover_zoom_line"
                            data-animation-in="none"
                            data-animation-out="none"
                          >
                            <ul
                              id="sc_layouts_menu_1593324209"
                              className="sc_layouts_menu_nav"
                            >
                              <li
                                id="menu-item-26257"
                                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-26257"
                              >
                                <a href="/">
                                  <span>Home</span>
                                </a>
                              </li>
                              <li
                                id="menu-item-25953"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-25953"
                              >
                                <a href="/about">
                                  <span>About</span>
                                </a>
                              </li>
                              <li
                                id="menu-item-28034"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-28034"
                              >
                                <a href="/policy">
                                  <span>Policy</span>
                                </a>
                              </li>
                              <li
                                id="menu-item-26084"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-26084"
                              >
                                <a href="/terms">
                                  <span>Terms</span>
                                </a>
                              </li>
                            </ul>
                          </nav>{" "}
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </section>
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-5d1720a elementor-section-full_width elementor-section-content-middle sc_layouts_row sc_layouts_row_type_compact sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_layouts_row_fixed sc_layouts_row_fixed_always sc_layouts_row_delay_fixed sc_layouts_row_hide_unfixed scheme_light elementor-section-height-default elementor-section-height-default sc_fly_static"
                data-id="5d1720a"
                data-element_type="section"
                data-settings='{"background_background":"classic"}'
              >
                <div className="elementor-container elementor-column-gap-extended">
                  <div
                    className="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-aabc833 sc_layouts_column_align_left sc_layouts_column sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                    data-id="aabc833"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="sc_layouts_item elementor-element elementor-element-1dfad3c sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_logo"
                        data-id="1dfad3c"
                        data-element_type="widget"
                        data-widget_type="trx_sc_layouts_logo.default"
                      >
                        <div className="elementor-widget-container">
                          <a
                            href="#"
                            className="sc_layouts_logo sc_layouts_logo_default trx_addons_inline_569760341"
                          >
                            <img
                              loading="lazy"
                              className="logo_image"
                              src="//spin.axiomthemes.com/wp-content/uploads/2023/07/new-logo.png"
                              srcSet="//spin.axiomthemes.com/wp-content/uploads/2023/07/new-logo-2x.png 2x"
                              alt="Spin"
                              width={108}
                              height={80}
                            />
                          </a>{" "}
                        </div>
                      </div>
                      <div
                        className="sc_layouts_item elementor-element elementor-element-1124ce9 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_menu"
                        data-id="1124ce9"
                        data-element_type="widget"
                        data-widget_type="trx_sc_layouts_menu.default"
                      >
                        <div className="elementor-widget-container">
                          <nav
                            className="sc_layouts_menu sc_layouts_menu_default sc_layouts_menu_dir_horizontal menu_hover_zoom_line"
                            data-animation-in="none"
                            data-animation-out="none"
                          >
                            <ul
                              id="sc_layouts_menu_1593324209"
                              className="sc_layouts_menu_nav"
                            >
                              <li
                                id="menu-item-26257"
                                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-26257"
                              >
                                <a href="/">
                                  <span>Home</span>
                                </a>
                              </li>
                              <li
                                id="menu-item-25953"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-25953"
                              >
                                <a href="/about">
                                  <span>About</span>
                                </a>
                              </li>
                              <li
                                id="menu-item-28034"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-28034"
                              >
                                <a href="/policy">
                                  <span>Policy</span>
                                </a>
                              </li>
                              <li
                                id="menu-item-26084"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-26084"
                              >
                                <a href="/terms">
                                  <span>Terms</span>
                                </a>
                              </li>
                            </ul>
                          </nav>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-4b171d2 sc_layouts_column_align_right sc_layouts_column sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                    data-id="4b171d2"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="sc_layouts_item elementor-element elementor-element-a96af53 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_cart"
                        data-id="a96af53"
                        data-element_type="widget"
                        data-widget_type="trx_sc_layouts_cart.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="sc_layouts_cart sc_layouts_cart_market_woocommerce">
                            <span className="sc_layouts_item_icon sc_layouts_cart_icon sc_icons_type_icons trx_addons_icon-basket" />
                            <span className="sc_layouts_item_details sc_layouts_cart_details">
                              <span className="sc_layouts_item_details_line2 sc_layouts_cart_totals">
                                <span
                                  className="sc_layouts_cart_items"
                                  data-item="item"
                                  data-items="items"
                                >
                                  0 items
                                </span>
                                -
                                <span className="sc_layouts_cart_summa">$0.00</span>
                              </span>
                            </span>
                            <span className="sc_layouts_cart_items_short">0</span>
                            <div className="sc_layouts_cart_widget widget_area">
                              <span className="sc_layouts_cart_widget_close trx_addons_button_close">
                                <span className="sc_layouts_cart_widget_close_icon trx_addons_button_close_icon" />
                              </span>
                              <div className="widget woocommerce widget_shopping_cart">
                                <div className="widget_shopping_cart_content" />
                              </div>{" "}
                            </div>
                          </div>{" "}
                        </div>
                      </div>
                      <div
                        className="sc_layouts_item elementor-element elementor-element-b9d44de sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_search"
                        data-id="b9d44de"
                        data-element_type="widget"
                        data-widget_type="trx_sc_layouts_search.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="sc_layouts_search">
                            <div className="search_modern">
                              <span className="search_submit" />
                              <div className="search_wrap">
                                <div className="search_header_wrap">
                                  <img
                                    loading="lazy"
                                    className="logo_image"
                                    src="//spin.axiomthemes.com/wp-content/uploads/2023/07/new-logo.png"
                                    srcSet="//spin.axiomthemes.com/wp-content/uploads/2023/07/new-logo-2x.png 2x"
                                    alt="Spin"
                                    width={108}
                                    height={80}
                                  />{" "}
                                  <a className="search_close" />
                                </div>
                                <div className="search_form_wrap">
                                  <form
                                    role="search"
                                    method="get"
                                    className="search_form"
                                    action="https://spin.axiomthemes.com/"
                                  >
                                    <input
                                      type="hidden"
                                      defaultValue=""
                                      name="post_types"
                                    />
                                    <input
                                      type="text"
                                      className="search_field"
                                      placeholder="Type words and hit enter"
                                      defaultValue=""
                                      name="s"
                                    />
                                    <button
                                      type="submit"
                                      className="search_submit"
                                    />
                                  </form>
                                </div>
                              </div>
                              <div className="search_overlay" />
                            </div>
                          </div>
                          {/* /.sc_layouts_search */}{" "}
                        </div>
                      </div>
                      <div
                        className="sc_layouts_item elementor-element elementor-element-7f57735 elementor-view-default sc_fly_static elementor-widget elementor-widget-icon"
                        data-id="7f57735"
                        data-element_type="widget"
                        data-widget_type="icon.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-icon-wrapper">
                            <a className="elementor-icon" href="#popup-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={21}
                                height={21}
                                viewBox="0 0 21 21"
                              >
                                <g
                                  className="right_bar"
                                  transform="translate(-2124 -2665)"
                                >
                                  <g
                                    transform="translate(2124 2665)"
                                    fill="none"
                                    strokeWidth="1.5"
                                  >
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="2.5"
                                      stroke="none"
                                    />
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="1.75"
                                      fill="none"
                                    />
                                  </g>
                                  <g
                                    transform="translate(2132 2665)"
                                    fill="none"
                                    strokeWidth="1.5"
                                  >
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="2.5"
                                      stroke="none"
                                    />
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="1.75"
                                      fill="none"
                                    />
                                  </g>
                                  <g
                                    transform="translate(2140 2665)"
                                    fill="none"
                                    strokeWidth="1.5"
                                  >
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="2.5"
                                      stroke="none"
                                    />
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="1.75"
                                      fill="none"
                                    />
                                  </g>
                                  <g
                                    transform="translate(2124 2673)"
                                    fill="none"
                                    strokeWidth="1.5"
                                  >
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="2.5"
                                      stroke="none"
                                    />
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="1.75"
                                      fill="none"
                                    />
                                  </g>
                                  <g
                                    transform="translate(2132 2673)"
                                    fill="none"
                                    strokeWidth="1.5"
                                  >
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="2.5"
                                      stroke="none"
                                    />
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="1.75"
                                      fill="none"
                                    />
                                  </g>
                                  <g
                                    transform="translate(2140 2673)"
                                    fill="none"
                                    strokeWidth="1.5"
                                  >
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="2.5"
                                      stroke="none"
                                    />
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="1.75"
                                      fill="none"
                                    />
                                  </g>
                                  <g
                                    transform="translate(2124 2681)"
                                    fill="none"
                                    strokeWidth="1.5"
                                  >
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="2.5"
                                      stroke="none"
                                    />
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="1.75"
                                      fill="none"
                                    />
                                  </g>
                                  <g
                                    transform="translate(2132 2681)"
                                    fill="none"
                                    strokeWidth="1.5"
                                  >
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="2.5"
                                      stroke="none"
                                    />
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="1.75"
                                      fill="none"
                                    />
                                  </g>
                                  <g
                                    transform="translate(2140 2681)"
                                    fill="none"
                                    strokeWidth="1.5"
                                  >
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="2.5"
                                      stroke="none"
                                    />
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="1.75"
                                      fill="none"
                                    />
                                  </g>
                                </g>
                              </svg>{" "}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-c79d134 elementor-section-content-middle sc_layouts_row sc_layouts_row_type_compact sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_layouts_hide_on_notebook elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                data-id="c79d134"
                data-element_type="section"
              >
                <div className="elementor-container elementor-column-gap-extended">
                  <div
                    className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b40d63d sc_layouts_column_align_left sc_layouts_column sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                    data-id="b40d63d"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="sc_layouts_item elementor-element elementor-element-8ec53d7 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_logo"
                        data-id="8ec53d7"
                        data-element_type="widget"
                        data-widget_type="trx_sc_layouts_logo.default"
                      >
                        <div className="elementor-widget-container">
                          <a
                            href="#"
                            className="sc_layouts_logo sc_layouts_logo_default trx_addons_inline_667427717"
                          >
                            <img
                              loading="lazy"
                              className="logo_image"
                              src="//spin.axiomthemes.com/wp-content/uploads/2023/07/new-logo.png"
                              srcSet="//spin.axiomthemes.com/wp-content/uploads/2023/07/new-logo-2x.png 2x"
                              alt="Spin"
                              width={108}
                              height={80}
                            />
                          </a>{" "}
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </section>
            </div>
          </header>
          <div className="menu_mobile_overlay scheme_dark" />
          <div className="menu_mobile menu_mobile_fullscreen scheme_dark">
            <div className="menu_mobile_inner">
              <div className="menu_mobile_header_wrap">
                <a className="sc_layouts_logo" href="https://spin.axiomthemes.com/">
                  <img
                    loading="lazy"
                    src="//spin.axiomthemes.com/wp-content/uploads/2023/07/new-logo.png"
                    srcSet="//spin.axiomthemes.com/wp-content/uploads/2023/07/new-logo-2x.png 2x"
                    alt="Spin"
                    width={108}
                    height={80}
                  />{" "}
                </a>
                <a className="menu_mobile_close menu_button_close" tabIndex={0}>
                  <span className="menu_button_close_text">Close</span>
                  <span className="menu_button_close_icon" />
                </a>
              </div>
              <div className="menu_mobile_content_wrap content_wrap">
                <div className="menu_mobile_content_wrap_inner">
                  <nav
                    className="menu_mobile_nav_area"
                    itemScope="itemscope"
                    itemType="https://schema.org/SiteNavigationElement"
                  >
                    <ul id="menu_mobile" className=" menu_mobile_nav">
                      <li className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-26257">
                        <a href="/">
                          <span>Home</span>
                        </a>
                      </li>
                      <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-25953">
                        <a href="/about">
                          <span>About</span>
                        </a>
                      </li>
                      <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-28034">
                        <a href="/policy">
                          <span>Policy</span>
                        </a>

                      </li>
                      <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-26084">
                        <a href="/terms">
                          <span>Terms</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                  <div className="socials_mobile">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/AxiomThemes-505060569826537/"
                      className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"
                    >
                      <span
                        className="social_icon social_icon_facebook-1"
                        style={{}}
                      >
                        <span className="icon-facebook-1" />
                      </span>
                    </a>
                    <a
                      target="_blank"
                      href="https://twitter.com/ThemesAxiom/"
                      className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"
                    >
                      <span
                        className="social_icon social_icon_twitter-new"
                        style={{}}
                      >
                        <span className="icon-twitter-new" />
                      </span>
                    </a>
                    <a
                      target="_blank"
                      href="https://dribbble.com/axiomthemes/"
                      className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"
                    >
                      <span
                        className="social_icon social_icon_dribble-new"
                        style={{}}
                      >
                        <span className="icon-dribble-new" />
                      </span>
                    </a>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/axiom_themes/"
                      className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"
                    >
                      <span
                        className="social_icon social_icon_instagram"
                        style={{}}
                      >
                        <span className="icon-instagram" />
                      </span>
                    </a>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="page_content_wrap">
            <div className="content_wrap_fullscreen">
              <div className="content">
                <a
                  id="content_skip_link_anchor"
                  className="spin_skip_link_anchor"
                  href="#"
                />
                <article
                  id="post-5002"
                  className="post_item_single post_type_page post-5002 page type-page status-publish hentry"
                >
                  <div className="post_content entry-content">
                    <div
                      data-elementor-type="wp-page"
                      data-elementor-id={5002}
                      className="elementor elementor-5002"
                    >
                      <section
                        className="elementor-section elementor-top-section elementor-element elementor-element-3b86507 elementor-section-full_width elementor-section-height-default elementor-section-height-default sc_fly_static"
                        data-id="3b86507"
                        data-element_type="section"
                        data-settings='{"background_background":"classic"}'
                      >
                        <div className="elementor-container elementor-column-gap-extended">
                          <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b16d9a7 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-id="b16d9a7"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-a044b3c sc_fly_static elementor-widget elementor-widget-trx_widget_slider"
                                data-id="a044b3c"
                                data-element_type="widget"
                                data-widget_type="trx_widget_slider.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="widget_area sc_widget_slider">
                                    <aside className="widget widget_slider">
                                      {" "}
                                      <div className="slider_wrap slider_engine_revo slider_alias_slider-1">
                                        <div className="wp-block-themepunch-revslider 0">
                                          {/* START Slider 1 REVOLUTION SLIDER 6.6.19 */}
                                          <p className="rs-p-wp-fix" />
                                          <rs-module-wrap
                                            id="rev_slider_5_1_wrapper"
                                            data-source="post"
                                            style={{
                                              visibility: "hidden",
                                              background: "transparent",
                                              padding: 0
                                            }}
                                          >
                                            <rs-module
                                              id="rev_slider_5_1"
                                              style={{}}
                                              data-version="6.6.19"
                                            >
                                              <rs-slides
                                                style={{
                                                  overflow: "hidden",
                                                  position: "absolute"
                                                }}
                                              >
                                                <rs-slide
                                                  style={{ position: "absolute" }}
                                                  data-key="rs-19483"
                                                  data-title="Goals that make games really memorable"
                                                  data-anim="adpr:false;ms:300ms;"
                                                  data-in="o:0;"
                                                  data-out="a:false;"
                                                >
                                                  <img
                                                    fetchpriority="high"
                                                    decoding="async"
                                                    src="//spin.axiomthemes.com/wp-content/plugins/revslider/public/assets/assets/dummy.png"
                                                    alt=""
                                                    title="181_1-1-copyright"
                                                    width={1920}
                                                    height={980}
                                                    className="rev-slidebg tp-rs-img rs-lazyload"
                                                    data-lazyload="//spin.axiomthemes.com/wp-content/uploads/2023/09/181_1-1-copyright.jpg"
                                                    data-parallax="off"
                                                    data-panzoom="d:10000;ss:100;se:110%;"
                                                    data-no-retina=""
                                                  />
                                                  {/*						*/}
                                                  <rs-zone
                                                    id="rrzm_19483"
                                                    className="rev_row_zone_middle"
                                                    style={{ zIndex: 8 }}
                                                  >
                                                    {/*
  
            */}
                                                    <rs-row
                                                      id="slider-5-slide-19483-layer-3"
                                                      data-type="row"
                                                      data-xy="xo:50px;yo:50px;"
                                                      data-cbreak="nobreak"
                                                      data-basealign="slide"
                                                      data-wrpcls="slider-row-wrap"
                                                      data-rsp_bd="off"
                                                      data-margin="l:70,70,30,20;"
                                                      data-frame_0="o:1;"
                                                      data-frame_999="o:0;st:w;sR:8700;sA:9000;"
                                                      style={{ zIndex: 6 }}
                                                    >
                                                      {/*
                                                   */}
                                                      <rs-column
                                                        id="slider-5-slide-19483-layer-4"
                                                        data-type="column"
                                                        data-xy="xo:50px;yo:50px;"
                                                        data-text="l:28,26,26,26;"
                                                        data-rsp_bd="off"
                                                        data-column="w:100%;"
                                                        data-frame_0="o:1;"
                                                        data-frame_999="o:0;st:w;sR:8700;sA:9000;"
                                                        style={{
                                                          zIndex: 6,
                                                          width: "100%"
                                                        }}
                                                      >
                                                        {/*
                                                     */}
                                                        <rs-layer
                                                          id="slider-5-slide-19483-layer-2"
                                                          data-type="shape"
                                                          data-rsp_ch="on"
                                                          data-xy="xo:50px,37px,19px,11px;yo:50px,37px,19px,11px;"
                                                          data-pos="r"
                                                          data-text="w:normal;s:20,15,8,4;c:both;l:0,18,9,6;"
                                                          data-flcr="c:both;"
                                                          data-dim="w:100%;h:0px,20px,40px,40px;"
                                                          data-frame_999="o:0;st:w;sR:8700;"
                                                          style={{ zIndex: 13 }}
                                                        ></rs-layer>
                                                        {/*
  
                */}
                                                        <rs-layer
                                                          id="slider-5-slide-19483-layer-5"
                                                          data-type="text"
                                                          data-xy=""
                                                          data-pos="r"
                                                          data-text="w:normal;s:15,13,13,13;l:20;ls:1px;fw:600;"
                                                          data-rsp_o="off"
                                                          data-rsp_bd="off"
                                                          data-disp="inline-block"
                                                          data-frame_0="y:100%;"
                                                          data-frame_0_mask="u:t;"
                                                          data-frame_1="st:270;sp:1200;sR:270;"
                                                          data-frame_1_mask="u:t;"
                                                          data-frame_999="o:0;st:w;sR:7530;"
                                                          style={{
                                                            zIndex: 12,
                                                            fontFamily:
                                                              '"Inter Tight"',
                                                            textTransform:
                                                              "uppercase",
                                                            display: "inline-block"
                                                          }}
                                                        >
                                                          Digest
                                                        </rs-layer>
                                                        {/*
  
                */}
                                                        <rs-layer
                                                          id="slider-5-slide-19483-layer-1"
                                                          data-type="shape"
                                                          data-xy="xo:50px,37px,19px,11px;yo:260px,195px,105px,64px;"
                                                          data-pos="r"
                                                          data-text="w:normal;s:20,15,8,4;c:both;l:0,18,9,6;"
                                                          data-flcr="c:both;"
                                                          data-dim="w:100%;h:13px,6px,6px,4px;"
                                                          data-rsp_o="off"
                                                          data-rsp_bd="off"
                                                          data-frame_999="o:0;st:w;sR:8700;"
                                                          style={{ zIndex: 11 }}
                                                        ></rs-layer>
                                                        {/*
  
                */}

                                                        {/*
  
                */}
                                                        <rs-layer
                                                          id="slider-5-slide-19483-layer-23"
                                                          data-type="shape"
                                                          data-xy="xo:50px,37px,19px,11px;yo:260px,195px,105px,64px;"
                                                          data-pos="r"
                                                          data-text="w:normal;s:20,15,8,4;c:both;l:0,18,9,6;"
                                                          data-flcr="c:both;"
                                                          data-dim="w:100%;h:27px,14px,12px,14px;"
                                                          data-vbility="t,t,f,f"
                                                          data-rsp_o="off"
                                                          data-wrpcls="sldr-hide"
                                                          data-rsp_bd="off"
                                                          data-layeronlimit="on"
                                                          data-frame_999="o:0;st:w;sR:8700;"
                                                          style={{ zIndex: 9 }}
                                                        ></rs-layer>
                                                        {/*
  
                */}

                                                        {/*
  
                */}
                                                        <rs-layer
                                                          id="slider-5-slide-19483-layer-21"
                                                          data-type="shape"
                                                          data-xy="xo:50px,37px,19px,11px;yo:260px,195px,105px,64px;"
                                                          data-pos="r"
                                                          data-text="w:normal;s:20,15,8,4;c:both;l:0,18,9,6;"
                                                          data-flcr="c:both;"
                                                          data-dim="w:100%;h:38px,18px,21px,16px;"
                                                          data-rsp_o="off"
                                                          data-rsp_bd="off"
                                                          data-frame_999="o:0;st:w;sR:8700;"
                                                          style={{ zIndex: 7 }}
                                                        ></rs-layer>
                                                        {/*
  
                */}
                                                        <a
                                                          id="slider-5-slide-19483-layer-22"
                                                          className="rs-layer sldr-button rev-btn"
                                                          href="/about"
                                                          target="_self"
                                                          data-type="button"
                                                          data-xy="xo:0,36px,36px,36px;yo:0,493px,493px,493px;"
                                                          data-pos="r"
                                                          data-text="w:normal;s:13,12,12,12;l:54,50,48,46;ls:1px;fw:600;"
                                                          data-dim="minh:0px,none,none,none;"
                                                          data-rsp_o="off"
                                                          data-rsp_bd="off"
                                                          data-disp="inline-block"
                                                          data-padding="r:50,44,40,36;l:50,44,40,36;"
                                                          data-border="bor:100px,100px,100px,100px;"
                                                          data-frame_0="y:100%;o:1;"
                                                          data-frame_0_mask="u:t;"
                                                          data-frame_1="e:power2.out;st:830;sp:1200;sR:830;"
                                                          data-frame_1_mask="u:t;"
                                                          data-frame_999="o:0;st:w;sR:6970;"
                                                          data-frame_hover="rX:0deg;rY:0deg;c:#fff;bgc:#c4301c;boc:#1f242e;bor:100px,100px,100px,100px;bos:solid;bow:0px,0px,0px,0px;sp:100ms;e:none;"
                                                          style={{
                                                            zIndex: 6,
                                                            backgroundColor:
                                                              "#e03821",
                                                            fontFamily:
                                                              '"Inter Tight"',
                                                            textTransform:
                                                              "uppercase",
                                                            display: "inline-block",
                                                            willChange: "transform",
                                                          }}
                                                        >
                                                          Read More
                                                        </a>
                                                        &nbsp;
                                                        <a
                                                          id="slider-5-slide-19483-layer-22"
                                                          className="rs-layer sldr-button rev-btn"
                                                          href="/home"
                                                          target="_self"
                                                          data-type="button"
                                                          data-xy="xo:0,36px,36px,36px;yo:0,493px,493px,493px;"
                                                          data-pos="r"
                                                          data-text="w:normal;s:13,12,12,12;l:54,50,48,46;ls:1px;fw:600;"
                                                          data-dim="minh:0px,none,none,none;"
                                                          data-rsp_o="off"
                                                          data-rsp_bd="off"
                                                          data-disp="inline-block"
                                                          data-padding="r:50,44,40,36;l:50,44,40,36;"
                                                          data-border="bor:100px,100px,100px,100px;"
                                                          data-frame_0="y:100%;o:1;"
                                                          data-frame_0_mask="u:t;"
                                                          data-frame_1="e:power2.out;st:830;sp:1200;sR:830;"
                                                          data-frame_1_mask="u:t;"
                                                          data-frame_999="o:0;st:w;sR:6970;"
                                                          data-frame_hover="rX:0deg;rY:0deg;c:#fff;bgc:#c4301c;boc:#1f242e;bor:100px,100px,100px,100px;bos:solid;bow:0px,0px,0px,0px;sp:100ms;e:none;"
                                                          style={{
                                                            zIndex: 6,
                                                            backgroundColor:
                                                              "#e03821",
                                                            fontFamily:
                                                              '"Inter Tight"',
                                                            textTransform:
                                                              "uppercase",
                                                            display: "inline-block",
                                                            willChange: "transform"
                                                          }}
                                                        >
                                                          Download
                                                        </a>
                                                        {/*
                                                     */}
                                                      </rs-column>
                                                      {/*
                                                   */}
                                                    </rs-row>
                                                    {/*
                                                 */}
                                                  </rs-zone>
                                                  {/*
                                               */}
                                                  <rs-zone
                                                    id="rrzb_19483"
                                                    className="rev_row_zone_bottom"
                                                    style={{ zIndex: 7 }}
                                                  >
                                                    {/*
  
            */}
                                                    <rs-row
                                                      id="slider-5-slide-19483-layer-6"
                                                      data-type="row"
                                                      data-xy="xo:50px;yo:50px;"
                                                      data-cbreak="nobreak"
                                                      data-basealign="slide"
                                                      data-wrpcls="slider-row-wrap"
                                                      data-rsp_bd="off"
                                                      data-margin="r:70,70,30,20;b:35,30,20,20;l:70,70,30,20;"
                                                      data-frame_0="o:1;"
                                                      data-frame_999="o:0;st:w;sR:8700;sA:9000;"
                                                      style={{ zIndex: 6 }}
                                                    >
                                                      {/*
                                                   */}
                                                      <rs-column
                                                        id="slider-5-slide-19483-layer-7"
                                                        data-type="column"
                                                        data-xy="xo:50px;yo:50px;"
                                                        data-text="l:28,26,26,26;"
                                                        data-rsp_bd="off"
                                                        data-column="w:100%;"
                                                        data-frame_0="o:1;"
                                                        data-frame_999="o:0;st:w;sR:8700;sA:9000;"
                                                        style={{
                                                          zIndex: 6,
                                                          width: "100%"
                                                        }}
                                                      >
                                                        {/*
                                                     */}
                                                        <a
                                                          id="slider-5-slide-19483-layer-19"
                                                          className="rs-layer rev-btn"
                                                          href="https://www.instagram.com/axiom_themes/"
                                                          target="_blank"
                                                          rel="noopener"
                                                          data-type="button"
                                                          data-xy=""
                                                          data-pos="r"
                                                          data-text="w:normal;s:15,14,14,14;f:right;l:10;ls:1,0,0,0;"
                                                          data-flcr="f:right;"
                                                          data-dim="minh:0px,none,none,none;"
                                                          data-basealign="slide"
                                                          data-rsp_o="off"
                                                          data-rsp_bd="off"
                                                          data-disp="inline-block"
                                                          data-margin="r:-3,-6,-6,-6;l:10,4,0,0;"
                                                          data-padding="t:10;r:10;b:10;l:10;"
                                                          data-frame_0="y:100%;"
                                                          data-frame_0_mask="u:t;"
                                                          data-frame_1="st:1320;sp:1200;sR:1320;"
                                                          data-frame_1_mask="u:t;"
                                                          data-frame_999="o:0;st:w;sR:6480;"
                                                          data-frame_hover="c:rgba(255,255,255,0.8);bor:0px,0px,0px,0px;sp:200;"
                                                          style={{
                                                            zIndex: 11,
                                                            fontFamily: '"Roboto"',
                                                            textTransform:
                                                              "uppercase",
                                                            display: "inline-block"
                                                          }}
                                                        >
                                                          <span className="icon-instagram" />
                                                        </a>
                                                        {/*
  
                */}
                                                        <a
                                                          id="slider-5-slide-19483-layer-25"
                                                          className="rs-layer rev-btn"
                                                          href="https://dribbble.com/axiomthemes/"
                                                          target="_blank"
                                                          rel="noopener"
                                                          data-type="button"
                                                          data-xy="xo:0,127px,127px,127px;yo:0,27px,27px,27px;"
                                                          data-pos="r"
                                                          data-text="w:normal;s:15,14,14,14;f:right;l:10;ls:1,0,0,0;"
                                                          data-flcr="f:right;"
                                                          data-dim="minh:0px,none,none,none;"
                                                          data-basealign="slide"
                                                          data-rsp_o="off"
                                                          data-rsp_bd="off"
                                                          data-disp="inline-block"
                                                          data-margin="l:10,4,0,0;"
                                                          data-padding="t:10;r:10;b:10;l:10;"
                                                          data-frame_0="y:100%;"
                                                          data-frame_0_mask="u:t;"
                                                          data-frame_1="st:1210;sp:1200;sR:1210;"
                                                          data-frame_1_mask="u:t;"
                                                          data-frame_999="o:0;st:w;sR:6590;"
                                                          data-frame_hover="c:rgba(255,255,255,0.8);bor:0px,0px,0px,0px;sp:200;"
                                                          style={{
                                                            zIndex: 10,
                                                            fontFamily: '"Roboto"',
                                                            textTransform:
                                                              "uppercase",
                                                            display: "inline-block"
                                                          }}
                                                        >
                                                          <span className="icon-dribble-new" />
                                                        </a>
                                                        {/*
  
                */}
                                                        <a
                                                          id="slider-5-slide-19483-layer-18"
                                                          className="rs-layer rev-btn"
                                                          href="https://twitter.com/ThemesAxiom/"
                                                          target="_blank"
                                                          rel="noopener"
                                                          data-type="button"
                                                          data-xy="xo:0,77px,77px,77px;yo:0,26px,26px,26px;"
                                                          data-pos="r"
                                                          data-text="w:normal;s:13,12,12,12;f:right;l:10;ls:1,0,0,0;"
                                                          data-flcr="f:right;"
                                                          data-dim="minh:0px,none,none,none;"
                                                          data-basealign="slide"
                                                          data-rsp_o="off"
                                                          data-rsp_bd="off"
                                                          data-disp="inline-block"
                                                          data-margin="l:10,4,0,0;"
                                                          data-padding="t:10;r:10;b:10;l:10;"
                                                          data-frame_0="y:100%;"
                                                          data-frame_0_mask="u:t;"
                                                          data-frame_1="st:1090;sp:1200;sR:1090;"
                                                          data-frame_1_mask="u:t;"
                                                          data-frame_999="o:0;st:w;sR:6710;"
                                                          data-frame_hover="c:rgba(255,255,255,0.8);bor:0px,0px,0px,0px;sp:200;"
                                                          style={{
                                                            zIndex: 9,
                                                            fontFamily: '"Roboto"',
                                                            textTransform:
                                                              "uppercase",
                                                            display: "inline-block"
                                                          }}
                                                        >
                                                          <span className="icon-twitter-new" />
                                                        </a>
                                                        {/*
  
                */}
                                                        <a
                                                          id="slider-5-slide-19483-layer-8"
                                                          className="rs-layer rev-btn"
                                                          href="https://www.facebook.com/AxiomThemes-505060569826537/"
                                                          target="_blank"
                                                          rel="noopener"
                                                          data-type="button"
                                                          data-xy="xo:0,32px,32px,32px;yo:0,25px,25px,25px;"
                                                          data-pos="r"
                                                          data-text="w:normal;s:15,14,14,14;f:right;l:10;ls:1,0,0,0;"
                                                          data-flcr="f:right;"
                                                          data-dim="minh:0px,none,none,none;"
                                                          data-basealign="slide"
                                                          data-rsp_o="off"
                                                          data-rsp_bd="off"
                                                          data-disp="inline-block"
                                                          data-padding="t:10;r:10;b:10;l:10;"
                                                          data-frame_0="y:100%;"
                                                          data-frame_0_mask="u:t;"
                                                          data-frame_1="st:990;sp:1200;sR:990;"
                                                          data-frame_1_mask="u:t;"
                                                          data-frame_999="o:0;st:w;sR:6810;"
                                                          data-frame_hover="c:rgba(255,255,255,0.8);bor:0px,0px,0px,0px;sp:200;"
                                                          style={{
                                                            zIndex: 8,
                                                            fontFamily: '"Roboto"',
                                                            textTransform:
                                                              "uppercase",
                                                            display: "inline-block"
                                                          }}
                                                        >
                                                          <span className="icon-facebook-1" />
                                                        </a>
                                                        {/*
  
                */}

                                                        {/*
  
                */}

                                                        {/*
                                                     */}
                                                      </rs-column>
                                                      {/*
                                                   */}
                                                    </rs-row>
                                                    {/*
                                                 */}
                                                  </rs-zone>
                                                  {/*
  
          */}
                                                  <rs-group
                                                    id="slider-5-slide-19483-layer-0"
                                                    data-type="group"
                                                    data-xy="x:r;xo:74px,72px,72px,72px;y:m;yo:-10px;"
                                                    data-text="w:normal;"
                                                    data-dim="w:360px,322px,322px,322px;h:440px,355px,355px,355px;"
                                                    data-vbility="t,t,f,f"
                                                    data-basealign="slide"
                                                    data-rsp_o="off"
                                                    data-wrpcls="sldr-group"
                                                    data-layeronlimit="on"
                                                    data-frame_0="x:0,0px,0px,0px;y:0,0px,0px,0px;o:1;"
                                                    data-frame_1="x:0,0px,0px,0px;y:0,0px,0px,0px;"
                                                    data-frame_999="o:0;st:w;sR:8700;sA:9000;"
                                                    style={{ zIndex: 10 }}
                                                  >
                                                    {/*
                                                 */}

                                                    {/*
  
            */}

                                                    {/*
  
            */}

                                                    {/*
  
            */}

                                                    {/*
  
            */}

                                                    {/*
  
            */}
                                                    <rs-layer
                                                      id="slider-5-slide-19483-layer-16"
                                                      data-type="shape"
                                                      data-xy="xo:293,261px,261px,261px;yo:387,344px,344px,344px;"
                                                      data-pos="r"
                                                      data-text="w:normal;"
                                                      data-dim="w:3px;h:3px;"
                                                      data-rsp_o="off"
                                                      data-rsp_bd="off"
                                                      data-disp="inline-block"
                                                      data-margin="t:10;r:10;l:10;"
                                                      data-border="bor:100%,100%,100%,100%;"
                                                      data-frame_0="x:0,0px,0px,0px;y:100%;"
                                                      data-frame_0_mask="u:t;x:0,0px,0px,0px;y:0,0px,0px,0px;"
                                                      data-frame_1="x:0,0px,0px,0px;y:0,0px,0px,0px;st:2250;sp:1200;sR:2250;"
                                                      data-frame_1_mask="u:t;x:0,0px,0px,0px;y:0,0px,0px,0px;"
                                                      data-frame_999="o:0;st:w;sR:5550;"
                                                      style={{
                                                        zIndex: 9,
                                                        backgroundColor: "#a5a6aa",
                                                        display: "inline-block"
                                                      }}
                                                    ></rs-layer>
                                                    {/*
  
            */}

                                                    {/*
  
            */}
                                                    <rs-layer
                                                      id="slider-5-slide-19483-layer-15"
                                                      data-type="shape"
                                                      data-xy="xo:385px,343px,343px,343px;yo:250px,223px,223px,223px;"
                                                      data-pos="r"
                                                      data-text="w:normal;c:both;"
                                                      data-flcr="c:both;"
                                                      data-dim="w:100%;h:6px,5px,5px,5px;"
                                                      data-rsp_o="off"
                                                      data-rsp_bd="off"
                                                      data-frame_0="x:0,0px,0px,0px;y:0,0px,0px,0px;"
                                                      data-frame_1="x:0,0px,0px,0px;y:0,0px,0px,0px;"
                                                      data-frame_999="o:0;st:w;sR:8700;"
                                                      style={{ zIndex: 7 }}
                                                    ></rs-layer>
                                                    {/*
  
            */}

                                                    {/*
                                                 */}
                                                  </rs-group>
                                                  {/*
  
          */}
                                                  <rs-layer
                                                    id="slider-5-slide-19483-layer-28"
                                                    data-type="text"
                                                    data-color="rgba(255, 255, 255, 0.1)"
                                                    data-rsp_ch="on"
                                                    data-xy="x:c;y:b;yo:-60px,-45px,-24px,-14px;"
                                                    data-text="w:normal;s:635,476,257,158;l:508,381,205,126;fw:600;"
                                                    data-basealign="slide"
                                                    data-frame_0="o:1;"
                                                    data-frame_0_chars="d:5;y:100%;o:0;rZ:-35deg;"
                                                    data-frame_0_mask="u:t;"
                                                    data-frame_1="e:power0.inOut;sp:2700;"
                                                    data-frame_1_chars="e:power4.inOut;d:10;rZ:0deg;"
                                                    data-frame_1_mask="u:t;"
                                                    data-frame_999="o:0;st:w;sR:5300;"
                                                    style={{
                                                      zIndex: 6,
                                                      fontFamily: '"Inter Tight"'
                                                    }}
                                                  >
                                                    cricket
                                                  </rs-layer>
                                                  {/*
  
          */}
                                                  <rs-layer
                                                    id="slider-5-slide-19483-layer-29"
                                                    data-type="shape"
                                                    data-rsp_ch="on"
                                                    data-xy="x:l,l,l,c;xo:50px,37px,19px,0;y:t,t,t,m;yo:50px,37px,19px,0;"
                                                    data-text="w:normal;s:20,15,8,4;l:0,18,9,6;"
                                                    data-dim="w:300px,225px,121px,100%;h:180px,135px,72px,100%;"
                                                    data-vbility="f,f,f,t"
                                                    data-basealign="slide"
                                                    data-frame_999="o:0;st:w;"
                                                    style={{
                                                      zIndex: 5,
                                                      backgroundColor:
                                                        "rgba(0,0,0,0.3)"
                                                    }}
                                                  ></rs-layer>
                                                  {/*
                                               */}{" "}
                                                </rs-slide>
                                              </rs-slides>
                                              <rs-static-layers>
                                                {/*
                                             */}
                                              </rs-static-layers>
                                            </rs-module>
                                          </rs-module-wrap>
                                          {/* END REVOLUTION SLIDER */}
                                        </div>{" "}
                                      </div>
                                    </aside>
                                  </div>{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section
                        className="elementor-section elementor-top-section elementor-element elementor-element-eff7a27 scheme_default elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                        data-id="eff7a27"
                        data-element_type="section"
                        data-settings='{"background_background":"classic"}'
                      >
                        <div className="elementor-container elementor-column-gap-extended">
                          <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ac53fea sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-id="ac53fea"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-50b2dda sc_fly_static elementor-widget elementor-widget-spacer"
                                data-id="50b2dda"
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section
                        className="elementor-section elementor-top-section elementor-element elementor-element-2263166 elementor-section-full_width scheme_default animation_type_sequental elementor-section-height-default elementor-section-height-default sc_fly_static elementor-invisible"
                        data-id={2263166}
                        data-element_type="section"
                        data-settings='{"background_background":"classic","animation":"spin-fadein","animation_delay":100}'
                      >
                        <div className="elementor-container elementor-column-gap-extended">
                          <div
                            className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-2ceb2c7 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-id="2ceb2c7"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-31b710a sc_fly_static elementor-widget elementor-widget-shortcode"
                                data-id="31b710a"
                                data-element_type="widget"
                                data-widget_type="shortcode.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-shortcode">
                                    <div className="sportspress">
                                      <div className="sp-template sp-template-event-blocks">
                                        <div className="sp-table-wrapper">
                                          <table
                                            className="sp-event-blocks sp-data-table
       sp-paginated-table"
                                            data-sp-rows={5}
                                          >
                                            <thead>
                                              <tr>
                                                <th />
                                              </tr>
                                            </thead>{" "}
                                            <tbody>
                                              <tr
                                                className="sp-row sp-post alternate"
                                                itemScope=""
                                                itemType="http://schema.org/SportsEvent"
                                              >
                                                <td>
                                                  <span
                                                    className="team-logo logo-odd"
                                                    title="Spin"
                                                  >
                                                    <a href="https://spin.axiomthemes.com/sport-team/spin/">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width={316}
                                                        height={312}
                                                        src="https://spin.axiomthemes.com/wp-content/uploads/2023/09/club-3-copyright.png"
                                                        className="attachment-sportspress-fit-icon size-sportspress-fit-icon wp-post-image"
                                                        alt=""
                                                      />
                                                    </a>
                                                  </span>{" "}
                                                  <span
                                                    className="team-logo logo-even"
                                                    title="Alligator"
                                                  >
                                                    <a href="https://spin.axiomthemes.com/sport-team/alligator/">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width={316}
                                                        height={312}
                                                        src="https://spin.axiomthemes.com/wp-content/uploads/2023/09/club-5-copyright.png"
                                                        className="attachment-sportspress-fit-icon size-sportspress-fit-icon wp-post-image"
                                                        alt=""
                                                      />
                                                    </a>
                                                  </span>{" "}
                                                  <time
                                                    className="sp-event-date"
                                                    dateTime="2023-08-25 20:00:00"
                                                    itemProp="startDate"
                                                    content="2023-08-25T20:00+00:00"
                                                  >
                                                    <a href="https://spin.axiomthemes.com/sport-event/spin-alligator/">
                                                      Match 1
                                                    </a>{" "}
                                                  </time>
                                                  <h5 className="sp-event-results">
                                                    <a href="https://spin.axiomthemes.com/sport-event/spin-alligator/">
                                                      <span className="sp-result ok">
                                                        140
                                                      </span>{" "}
                                                      -{" "}
                                                      <span className="sp-result">
                                                        110
                                                      </span>
                                                    </a>{" "}
                                                  </h5>
                                                  <div className="sp-event-league">
                                                    Premier League
                                                  </div>
                                                  <div className="sp-event-season">
                                                    2023
                                                  </div>
                                                  <div
                                                    className="sp-event-venue"
                                                    itemProp="location"
                                                    itemScope=""
                                                    itemType="http://schema.org/Place"
                                                  >
                                                    <div
                                                      itemProp="address"
                                                      itemScope=""
                                                      itemType="http://schema.org/PostalAddress"
                                                    >
                                                      Bentleigh
                                                    </div>
                                                  </div>
                                                  <div
                                                    style={{ display: "none" }}
                                                    className="sp-event-venue"
                                                    itemProp="location"
                                                    itemScope=""
                                                    itemType="http://schema.org/Place"
                                                  >
                                                    <div
                                                      itemProp="address"
                                                      itemScope=""
                                                      itemType="http://schema.org/PostalAddress"
                                                    >
                                                      N/A
                                                    </div>
                                                  </div>
                                                  <h4
                                                    className="sp-event-title"
                                                    itemProp="name"
                                                  >
                                                    <a href="https://spin.axiomthemes.com/sport-event/spin-alligator/">
                                                      Spin vs Alligator
                                                    </a>{" "}
                                                  </h4>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-0a3d8d1 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-id="0a3d8d1"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-f5d2451 sc_fly_static elementor-widget elementor-widget-shortcode"
                                data-id="f5d2451"
                                data-element_type="widget"
                                data-widget_type="shortcode.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-shortcode">
                                    <div className="sportspress">
                                      <div className="sp-template sp-template-event-blocks">
                                        <div className="sp-table-wrapper">
                                          <table
                                            className="sp-event-blocks sp-data-table
       sp-paginated-table"
                                            data-sp-rows={5}
                                          >
                                            <thead>
                                              <tr>
                                                <th />
                                              </tr>
                                            </thead>{" "}
                                            <tbody>
                                              <tr
                                                className="sp-row sp-post alternate"
                                                itemScope=""
                                                itemType="http://schema.org/SportsEvent"
                                              >
                                                <td>
                                                  <span
                                                    className="team-logo logo-odd"
                                                    title="Spin"
                                                  >
                                                    <a href="https://spin.axiomthemes.com/sport-team/spin/">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width={316}
                                                        height={312}
                                                        src="https://spin.axiomthemes.com/wp-content/uploads/2023/09/club-3-copyright.png"
                                                        className="attachment-sportspress-fit-icon size-sportspress-fit-icon wp-post-image"
                                                        alt=""
                                                      />
                                                    </a>
                                                  </span>{" "}
                                                  <span
                                                    className="team-logo logo-even"
                                                    title="Punjab Kings"
                                                  >
                                                    <a href="https://spin.axiomthemes.com/sport-team/punjab-kings/">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width={316}
                                                        height={312}
                                                        src="https://spin.axiomthemes.com/wp-content/uploads/2023/09/club-2-copyright.png"
                                                        className="attachment-sportspress-fit-icon size-sportspress-fit-icon wp-post-image"
                                                        alt=""
                                                      />
                                                    </a>
                                                  </span>{" "}
                                                  <time
                                                    className="sp-event-date"
                                                    dateTime="2023-09-28 11:33:47"
                                                    itemProp="startDate"
                                                    content="2023-09-28T11:33+00:00"
                                                  >
                                                    <a href="https://spin.axiomthemes.com/sport-event/spin-punjab-kings/">
                                                      Match 2
                                                    </a>{" "}
                                                  </time>
                                                  <h5 className="sp-event-results">
                                                    <a href="https://spin.axiomthemes.com/sport-event/spin-punjab-kings/">
                                                      <span className="sp-result ok">
                                                        117
                                                      </span>{" "}
                                                      -{" "}
                                                      <span className="sp-result">
                                                        106
                                                      </span>
                                                    </a>{" "}
                                                  </h5>
                                                  <div className="sp-event-league">
                                                    Premier League
                                                  </div>
                                                  <div className="sp-event-season">
                                                    2023
                                                  </div>
                                                  <div
                                                    className="sp-event-venue"
                                                    itemProp="location"
                                                    itemScope=""
                                                    itemType="http://schema.org/Place"
                                                  >
                                                    <div
                                                      itemProp="address"
                                                      itemScope=""
                                                      itemType="http://schema.org/PostalAddress"
                                                    >
                                                      Kensington
                                                    </div>
                                                  </div>
                                                  <div
                                                    style={{ display: "none" }}
                                                    className="sp-event-venue"
                                                    itemProp="location"
                                                    itemScope=""
                                                    itemType="http://schema.org/Place"
                                                  >
                                                    <div
                                                      itemProp="address"
                                                      itemScope=""
                                                      itemType="http://schema.org/PostalAddress"
                                                    >
                                                      N/A
                                                    </div>
                                                  </div>
                                                  <h4
                                                    className="sp-event-title"
                                                    itemProp="name"
                                                  >
                                                    <a href="https://spin.axiomthemes.com/sport-event/spin-punjab-kings/">
                                                      Spin vs Punjab Kings
                                                    </a>{" "}
                                                  </h4>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-457500e sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-id="457500e"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-3dfab06 sc_fly_static elementor-widget elementor-widget-shortcode"
                                data-id="3dfab06"
                                data-element_type="widget"
                                data-widget_type="shortcode.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-shortcode">
                                    <div className="sportspress">
                                      <div className="sp-template sp-template-event-blocks">
                                        <div className="sp-table-wrapper">
                                          <table
                                            className="sp-event-blocks sp-data-table
       sp-paginated-table"
                                            data-sp-rows={5}
                                          >
                                            <thead>
                                              <tr>
                                                <th />
                                              </tr>
                                            </thead>{" "}
                                            <tbody>
                                              <tr
                                                className="sp-row sp-post alternate"
                                                itemScope=""
                                                itemType="http://schema.org/SportsEvent"
                                              >
                                                <td>
                                                  <span
                                                    className="team-logo logo-odd"
                                                    title="Spin"
                                                  >
                                                    <a href="https://spin.axiomthemes.com/sport-team/spin/">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width={316}
                                                        height={312}
                                                        src="https://spin.axiomthemes.com/wp-content/uploads/2023/09/club-3-copyright.png"
                                                        className="attachment-sportspress-fit-icon size-sportspress-fit-icon wp-post-image"
                                                        alt=""
                                                      />
                                                    </a>
                                                  </span>{" "}
                                                  <span
                                                    className="team-logo logo-even"
                                                    title="Gujarat Titans"
                                                  >
                                                    <a href="https://spin.axiomthemes.com/sport-team/gujarat-titans/">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width={316}
                                                        height={312}
                                                        src="https://spin.axiomthemes.com/wp-content/uploads/2023/09/club-1-copyright.png"
                                                        className="attachment-sportspress-fit-icon size-sportspress-fit-icon wp-post-image"
                                                        alt=""
                                                      />
                                                    </a>
                                                  </span>{" "}
                                                  <time
                                                    className="sp-event-date"
                                                    dateTime="2023-07-18 06:08:20"
                                                    itemProp="startDate"
                                                    content="2023-07-18T06:08+00:00"
                                                  >
                                                    <a href="https://spin.axiomthemes.com/sport-event/spin-vs-alligator/">
                                                      Match 3
                                                    </a>{" "}
                                                  </time>
                                                  <h5 className="sp-event-results">
                                                    <a href="https://spin.axiomthemes.com/sport-event/spin-vs-alligator/">
                                                      <span className="sp-result ok">
                                                        121
                                                      </span>{" "}
                                                      -{" "}
                                                      <span className="sp-result">
                                                        105
                                                      </span>
                                                    </a>{" "}
                                                  </h5>
                                                  <div className="sp-event-league">
                                                    Premier League
                                                  </div>
                                                  <div className="sp-event-season">
                                                    2023
                                                  </div>
                                                  <div
                                                    className="sp-event-venue"
                                                    itemProp="location"
                                                    itemScope=""
                                                    itemType="http://schema.org/Place"
                                                  >
                                                    <div
                                                      itemProp="address"
                                                      itemScope=""
                                                      itemType="http://schema.org/PostalAddress"
                                                    >
                                                      New York
                                                    </div>
                                                  </div>
                                                  <div
                                                    style={{ display: "none" }}
                                                    className="sp-event-venue"
                                                    itemProp="location"
                                                    itemScope=""
                                                    itemType="http://schema.org/Place"
                                                  >
                                                    <div
                                                      itemProp="address"
                                                      itemScope=""
                                                      itemType="http://schema.org/PostalAddress"
                                                    >
                                                      N/A
                                                    </div>
                                                  </div>
                                                  <h4
                                                    className="sp-event-title"
                                                    itemProp="name"
                                                  >
                                                    <a href="https://spin.axiomthemes.com/sport-event/spin-vs-alligator/">
                                                      Spin vs Gujarat Titans
                                                    </a>{" "}
                                                  </h4>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-72ffcb2 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-id="72ffcb2"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-046b2f3 sc_fly_static elementor-widget elementor-widget-shortcode"
                                data-id="046b2f3"
                                data-element_type="widget"
                                data-widget_type="shortcode.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-shortcode">
                                    <div className="sportspress">
                                      <div className="sp-template sp-template-event-blocks">
                                        <div className="sp-table-wrapper">
                                          <table
                                            className="sp-event-blocks sp-data-table
       sp-paginated-table"
                                            data-sp-rows={5}
                                          >
                                            <thead>
                                              <tr>
                                                <th />
                                              </tr>
                                            </thead>{" "}
                                            <tbody>
                                              <tr
                                                className="sp-row sp-post alternate"
                                                itemScope=""
                                                itemType="http://schema.org/SportsEvent"
                                              >
                                                <td>
                                                  <span
                                                    className="team-logo logo-odd"
                                                    title="Durham"
                                                  >
                                                    <a href="https://spin.axiomthemes.com/sport-team/durham/">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width={316}
                                                        height={312}
                                                        src="https://spin.axiomthemes.com/wp-content/uploads/2023/09/club-4-copyright.png"
                                                        className="attachment-sportspress-fit-icon size-sportspress-fit-icon wp-post-image"
                                                        alt=""
                                                      />
                                                    </a>
                                                  </span>{" "}
                                                  <span
                                                    className="team-logo logo-even"
                                                    title="Spin"
                                                  >
                                                    <a href="https://spin.axiomthemes.com/sport-team/spin/">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width={316}
                                                        height={312}
                                                        src="https://spin.axiomthemes.com/wp-content/uploads/2023/09/club-3-copyright.png"
                                                        className="attachment-sportspress-fit-icon size-sportspress-fit-icon wp-post-image"
                                                        alt=""
                                                      />
                                                    </a>
                                                  </span>{" "}
                                                  <time
                                                    className="sp-event-date"
                                                    dateTime="2023-09-28 11:40:27"
                                                    itemProp="startDate"
                                                    content="2023-09-28T11:40+00:00"
                                                  >
                                                    <a href="https://spin.axiomthemes.com/sport-event/durham-vs-spin/">
                                                      Match 4
                                                    </a>{" "}
                                                  </time>
                                                  <h5 className="sp-event-results">
                                                    <a href="https://spin.axiomthemes.com/sport-event/durham-vs-spin/">
                                                      <span className="sp-result ok">
                                                        103
                                                      </span>{" "}
                                                      -{" "}
                                                      <span className="sp-result">
                                                        131
                                                      </span>
                                                    </a>{" "}
                                                  </h5>
                                                  <div className="sp-event-league">
                                                    Premier League
                                                  </div>
                                                  <div className="sp-event-season">
                                                    2023
                                                  </div>
                                                  <div
                                                    className="sp-event-venue"
                                                    itemProp="location"
                                                    itemScope=""
                                                    itemType="http://schema.org/Place"
                                                  >
                                                    <div
                                                      itemProp="address"
                                                      itemScope=""
                                                      itemType="http://schema.org/PostalAddress"
                                                    >
                                                      Bentleigh
                                                    </div>
                                                  </div>
                                                  <div
                                                    style={{ display: "none" }}
                                                    className="sp-event-venue"
                                                    itemProp="location"
                                                    itemScope=""
                                                    itemType="http://schema.org/Place"
                                                  >
                                                    <div
                                                      itemProp="address"
                                                      itemScope=""
                                                      itemType="http://schema.org/PostalAddress"
                                                    >
                                                      N/A
                                                    </div>
                                                  </div>
                                                  <h4
                                                    className="sp-event-title"
                                                    itemProp="name"
                                                  >
                                                    <a href="https://spin.axiomthemes.com/sport-event/durham-vs-spin/">
                                                      Durham vs Spin
                                                    </a>{" "}
                                                  </h4>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section
                        className="elementor-section elementor-top-section elementor-element elementor-element-9185306 scheme_default elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                        data-id={9185306}
                        data-element_type="section"
                        data-settings='{"background_background":"classic"}'
                      >
                        <div className="elementor-container elementor-column-gap-extended">
                          <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-66707f2 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-id="66707f2"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-0047494 sc_fly_static elementor-widget elementor-widget-spacer"

                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>


                      <section
                        className="elementor-section elementor-top-section elementor-element elementor-element-9250ba8 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                        data-id="9250ba8"
                        data-element_type="section"
                        data-settings='{"background_background":"classic"}'
                      >
                        <div className="elementor-background-overlay" />
                        <div className="elementor-container elementor-column-gap-extended">
                          <div
                            className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-02aa043 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-id="02aa043"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-2e27243 sc_fly_static elementor-widget elementor-widget-spacer"
                                data-id="2e27243"
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-057fb97 animation_type_block sc_fly_static elementor-invisible elementor-widget elementor-widget-trx_sc_title"
                                data-id="057fb97"
                                data-element_type="widget"
                                data-settings='{"_animation":"spin-fadeinright"}'
                                data-widget_type="trx_sc_title.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="sc_title sc_title_default">
                                    <span className="sc_item_subtitle sc_title_subtitle sc_item_subtitle_above sc_item_title_style_default">
                                      Welcome
                                    </span>
                                    <h1 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag">
                                      <span className="sc_item_title_text">
                                        Experience the true joy of professional cricket games!
                                      </span>
                                    </h1>
                                    <div className="sc_item_button sc_button_wrap sc_item_button sc_item_button_default sc_item_button_size_normal sc_title_button">
                                      {/* Download Button */}
                                      <a
                                        href="[Your App Download Link]"  // Replace [Your App Download Link] with the actual download link
                                        className="sc_button sc_button_default sc_button_size_normal sc_button_icon_left"
                                        target="_blank"  // Open the link in a new tab
                                        rel="noopener noreferrer"  // Add security attributes for opening in a new tab
                                      >
                                        <span className="sc_button_text">
                                          <span className="sc_button_title">
                                            Download Now
                                          </span>
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-b39ddd2 sc_fly_static elementor-widget elementor-widget-spacer"
                                data-id="b39ddd2"
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-c185455 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-id="c185455"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap">
                              {/* Dummy Image of Android Phone */}
                              <img
                                src="[YourImagePath]"  // Replace [YourImagePath] with the actual path to your image
                                alt="Mobile App Download"
                                style={{ width: '100%', height: 'auto' }}
                              />
                            </div>
                          </div>
                        </div>
                      </section>



                      <section
                        className="elementor-section elementor-top-section elementor-element elementor-element-9a37f77 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                        data-id="9a37f77"
                        data-element_type="section"
                        data-settings='{"background_background":"classic"}'
                      >
                        <div className="elementor-container elementor-column-gap-extended">
                          <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-bf59789 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-id="bf59789"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-eeb8cea sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer"
                                data-id="eeb8cea"
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section
                        className="elementor-section elementor-top-section elementor-element elementor-element-a64e0c3 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                        data-id="a64e0c3"
                        data-element_type="section"
                      >
                        <div className="elementor-container elementor-column-gap-extended">
                          <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7aae2ac sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-id="7aae2ac"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-b4274ad sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer"
                                data-id="b4274ad"
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-e0aa5d0 animation_type_block sc_fly_static elementor-invisible elementor-widget elementor-widget-trx_sc_title"
                                data-id="e0aa5d0"
                                data-element_type="widget"
                                data-settings='{"_animation":"spin-fadein"}'
                                data-widget_type="trx_sc_title.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="sc_title sc_title_default">
                                    <span className="sc_item_subtitle sc_title_subtitle sc_align_center sc_item_subtitle_above sc_item_title_style_default">
                                      table
                                    </span>
                                    <h1 className="sc_item_title sc_title_title sc_align_center sc_item_title_style_default sc_item_title_tag">
                                      <span className="sc_item_title_text">
                                        Premier league
                                      </span>
                                    </h1>
                                  </div>{" "}
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-817c1e8 sc_height_small sc_fly_static elementor-widget elementor-widget-spacer"
                                data-id="817c1e8"
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-19d8ef5 animation_type_block sc_fly_static elementor-invisible elementor-widget elementor-widget-shortcode"
                                data-id="19d8ef5"
                                data-element_type="widget"
                                data-settings='{"_animation":"spin-fadeinup"}'
                                data-widget_type="shortcode.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-shortcode">
                                    <div className="sportspress sp-widget-align-none">
                                      <div className="sp-template sp-template-league-table">
                                        <h4 className="sp-table-caption">
                                          Team standing
                                        </h4>
                                        <div className="sp-table-wrapper">
                                          <table
                                            className="sp-league-table sp-data-table sp-sortable-table sp-scrollable-table sp-paginated-table"
                                            data-sp-rows={10}
                                          >
                                            <thead>
                                              <tr>
                                                <th className="data-rank">Pos</th>
                                                <th className="data-name">Team</th>
                                                <th className="data-e">E</th>
                                                <th className="data-w">W</th>
                                                <th className="data-l">L</th>
                                                <th className="data-p">P</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr className="odd highlighted sp-row-no-0">
                                                <td
                                                  className="data-rank sp-highlight"
                                                  data-label="Pos"
                                                >
                                                  1
                                                </td>
                                                <td
                                                  className="data-name has-logo sp-highlight"
                                                  data-label="Team"
                                                >
                                                  <a href="https://spin.axiomthemes.com/sport-team/spin/">
                                                    <span className="team-logo">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width={316}
                                                        height={312}
                                                        src="https://spin.axiomthemes.com/wp-content/uploads/2023/09/club-3-copyright.png"
                                                        className="attachment-sportspress-fit-icon size-sportspress-fit-icon wp-post-image"
                                                        alt=""
                                                      />
                                                    </span>
                                                    Spin
                                                  </a>
                                                </td>
                                                <td
                                                  className="data-e sp-highlight"
                                                  data-label="E"
                                                >
                                                  5
                                                </td>
                                                <td
                                                  className="data-w sp-highlight"
                                                  data-label="W"
                                                >
                                                  4
                                                </td>
                                                <td
                                                  className="data-l sp-highlight"
                                                  data-label="L"
                                                >
                                                  1
                                                </td>
                                                <td
                                                  className="data-p sp-highlight"
                                                  data-label="P"
                                                >
                                                  8
                                                </td>
                                              </tr>
                                              <tr className="even sp-row-no-1">
                                                <td
                                                  className="data-rank"
                                                  data-label="Pos"
                                                >
                                                  2
                                                </td>
                                                <td
                                                  className="data-name has-logo"
                                                  data-label="Team"
                                                >
                                                  <a href="https://spin.axiomthemes.com/sport-team/eagles/">
                                                    <span className="team-logo">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width={316}
                                                        height={312}
                                                        src="https://spin.axiomthemes.com/wp-content/uploads/2023/09/club-10-copyright.png"
                                                        className="attachment-sportspress-fit-icon size-sportspress-fit-icon wp-post-image"
                                                        alt=""
                                                      />
                                                    </span>
                                                    Eagles
                                                  </a>
                                                </td>
                                                <td
                                                  className="data-e"
                                                  data-label="E"
                                                >
                                                  4
                                                </td>
                                                <td
                                                  className="data-w"
                                                  data-label="W"
                                                >
                                                  2
                                                </td>
                                                <td
                                                  className="data-l"
                                                  data-label="L"
                                                >
                                                  2
                                                </td>
                                                <td
                                                  className="data-p"
                                                  data-label="P"
                                                >
                                                  4
                                                </td>
                                              </tr>
                                              <tr className="odd sp-row-no-2">
                                                <td
                                                  className="data-rank"
                                                  data-label="Pos"
                                                >
                                                  3
                                                </td>
                                                <td
                                                  className="data-name has-logo"
                                                  data-label="Team"
                                                >
                                                  <a href="https://spin.axiomthemes.com/sport-team/bluebirds/">
                                                    <span className="team-logo">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width={316}
                                                        height={312}
                                                        src="https://spin.axiomthemes.com/wp-content/uploads/2023/09/club-7-copyright.png"
                                                        className="attachment-sportspress-fit-icon size-sportspress-fit-icon wp-post-image"
                                                        alt=""
                                                      />
                                                    </span>
                                                    Bluebirds
                                                  </a>
                                                </td>
                                                <td
                                                  className="data-e"
                                                  data-label="E"
                                                >
                                                  3
                                                </td>
                                                <td
                                                  className="data-w"
                                                  data-label="W"
                                                >
                                                  2
                                                </td>
                                                <td
                                                  className="data-l"
                                                  data-label="L"
                                                >
                                                  1
                                                </td>
                                                <td
                                                  className="data-p"
                                                  data-label="P"
                                                >
                                                  4
                                                </td>
                                              </tr>
                                              <tr className="even sp-row-no-3">
                                                <td
                                                  className="data-rank"
                                                  data-label="Pos"
                                                >
                                                  4
                                                </td>
                                                <td
                                                  className="data-name has-logo"
                                                  data-label="Team"
                                                >
                                                  <a href="https://spin.axiomthemes.com/sport-team/sharks/">
                                                    <span className="team-logo">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width={316}
                                                        height={312}
                                                        src="https://spin.axiomthemes.com/wp-content/uploads/2023/09/club-8-copyright.png"
                                                        className="attachment-sportspress-fit-icon size-sportspress-fit-icon wp-post-image"
                                                        alt=""
                                                      />
                                                    </span>
                                                    Sharks
                                                  </a>
                                                </td>
                                                <td
                                                  className="data-e"
                                                  data-label="E"
                                                >
                                                  2
                                                </td>
                                                <td
                                                  className="data-w"
                                                  data-label="W"
                                                >
                                                  2
                                                </td>
                                                <td
                                                  className="data-l"
                                                  data-label="L"
                                                >
                                                  0
                                                </td>
                                                <td
                                                  className="data-p"
                                                  data-label="P"
                                                >
                                                  4
                                                </td>
                                              </tr>
                                              <tr className="odd sp-row-no-4">
                                                <td
                                                  className="data-rank"
                                                  data-label="Pos"
                                                >
                                                  5
                                                </td>
                                                <td
                                                  className="data-name has-logo"
                                                  data-label="Team"
                                                >
                                                  <a href="https://spin.axiomthemes.com/sport-team/kangaroos/">
                                                    <span className="team-logo">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width={316}
                                                        height={312}
                                                        src="https://spin.axiomthemes.com/wp-content/uploads/2023/09/club-9-copyright.png"
                                                        className="attachment-sportspress-fit-icon size-sportspress-fit-icon wp-post-image"
                                                        alt=""
                                                      />
                                                    </span>
                                                    Kangaroos
                                                  </a>
                                                </td>
                                                <td
                                                  className="data-e"
                                                  data-label="E"
                                                >
                                                  3
                                                </td>
                                                <td
                                                  className="data-w"
                                                  data-label="W"
                                                >
                                                  1
                                                </td>
                                                <td
                                                  className="data-l"
                                                  data-label="L"
                                                >
                                                  2
                                                </td>
                                                <td
                                                  className="data-p"
                                                  data-label="P"
                                                >
                                                  2
                                                </td>
                                              </tr>
                                              <tr className="even sp-row-no-5">
                                                <td
                                                  className="data-rank"
                                                  data-label="Pos"
                                                >
                                                  6
                                                </td>
                                                <td
                                                  className="data-name has-logo"
                                                  data-label="Team"
                                                >
                                                  <a href="https://spin.axiomthemes.com/sport-team/durham/">
                                                    <span className="team-logo">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width={316}
                                                        height={312}
                                                        src="https://spin.axiomthemes.com/wp-content/uploads/2023/09/club-4-copyright.png"
                                                        className="attachment-sportspress-fit-icon size-sportspress-fit-icon wp-post-image"
                                                        alt=""
                                                      />
                                                    </span>
                                                    Durham
                                                  </a>
                                                </td>
                                                <td
                                                  className="data-e"
                                                  data-label="E"
                                                >
                                                  3
                                                </td>
                                                <td
                                                  className="data-w"
                                                  data-label="W"
                                                >
                                                  1
                                                </td>
                                                <td
                                                  className="data-l"
                                                  data-label="L"
                                                >
                                                  2
                                                </td>
                                                <td
                                                  className="data-p"
                                                  data-label="P"
                                                >
                                                  2
                                                </td>
                                              </tr>
                                              <tr className="odd sp-row-no-6">
                                                <td
                                                  className="data-rank"
                                                  data-label="Pos"
                                                >
                                                  7
                                                </td>
                                                <td
                                                  className="data-name has-logo"
                                                  data-label="Team"
                                                >
                                                  <a href="https://spin.axiomthemes.com/sport-team/punjab-kings/">
                                                    <span className="team-logo">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width={316}
                                                        height={312}
                                                        src="https://spin.axiomthemes.com/wp-content/uploads/2023/09/club-2-copyright.png"
                                                        className="attachment-sportspress-fit-icon size-sportspress-fit-icon wp-post-image"
                                                        alt=""
                                                      />
                                                    </span>
                                                    Punjab Kings
                                                  </a>
                                                </td>
                                                <td
                                                  className="data-e"
                                                  data-label="E"
                                                >
                                                  3
                                                </td>
                                                <td
                                                  className="data-w"
                                                  data-label="W"
                                                >
                                                  1
                                                </td>
                                                <td
                                                  className="data-l"
                                                  data-label="L"
                                                >
                                                  2
                                                </td>
                                                <td
                                                  className="data-p"
                                                  data-label="P"
                                                >
                                                  2
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                        <div className="sp-league-table-link sp-view-all-link">
                                          <a href="https://spin.axiomthemes.com/table/premier-league-2023/">
                                            View full table
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-e43cf50 sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer"
                                data-id="e43cf50"
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section
                        className="elementor-section elementor-top-section elementor-element elementor-element-b95cf93 scheme_light elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                        data-id="b95cf93"
                        data-element_type="section"
                        data-settings='{"background_background":"classic"}'
                      >
                        <div className="elementor-container elementor-column-gap-extended">
                          <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3372429 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-id={3372429}
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-14a1dba sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer"
                                data-id="14a1dba"
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-6d783b9 sc_fly_static elementor-widget elementor-widget-trx_sc_skills"
                                data-id="6d783b9"
                                data-element_type="widget"
                                data-widget_type="trx_sc_skills.default"
                              >
                                <div className="elementor-widget-container">
                                  <div
                                    className="sc_skills sc_skills_counter_extra sc_skills_counter_style_odometer sc_align_center"
                                    data-type="counter"
                                  >
                                    <div className="sc_skills_columns sc_item_columns trx_addons_columns_wrap columns_padding_bottom columns_in_single_row">
                                      <div className="sc_skills_column trx_addons_column-1_4 trx_addons_column-1_2-mobile">
                                        <div className="sc_skills_item_wrap sc_skills_item_icon_position_top">
                                          <div className="sc_skills_item_title">
                                            People
                                          </div>
                                          <div className="sc_skills_item">
                                            <div
                                              className="sc_skills_total"
                                              data-start={0}
                                              data-stop={90}
                                              data-step="0.9"
                                              data-max={2548}
                                              data-speed={15}
                                              data-duration={1500}
                                              data-ed="+"
                                              data-style="odometer"
                                            >
                                              <span className="sc_skills_digits">
                                                <span className="sc_skills_digit">
                                                  <span className="sc_skills_digit_placeholder">
                                                    8
                                                  </span>
                                                  <span className="sc_skills_digit_wrap">
                                                    <span className="sc_skills_digit_ribbon">
                                                      <span className="sc_skills_digit_value">
                                                        0
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                                <span className="sc_skills_digit">
                                                  <span className="sc_skills_digit_placeholder">
                                                    8
                                                  </span>
                                                  <span className="sc_skills_digit_wrap">
                                                    <span className="sc_skills_digit_ribbon">
                                                      <span className="sc_skills_digit_value">
                                                        0
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                                <span className="sc_skills_unit">
                                                  +
                                                </span>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="sc_skills_column trx_addons_column-1_4 trx_addons_column-1_2-mobile">
                                        <div className="sc_skills_item_wrap sc_skills_item_icon_position_top">
                                          <div className="sc_skills_item_title">
                                            Matches
                                          </div>
                                          <div className="sc_skills_item">
                                            <div
                                              className="sc_skills_total"
                                              data-start={0}
                                              data-stop={2548}
                                              data-step="25.48"
                                              data-max={2548}
                                              data-speed={15}
                                              data-duration={1500}
                                              data-ed=""
                                              data-style="odometer"
                                            >
                                              <span className="sc_skills_digits">
                                                <span className="sc_skills_digit">
                                                  <span className="sc_skills_digit_placeholder">
                                                    8
                                                  </span>
                                                  <span className="sc_skills_digit_wrap">
                                                    <span className="sc_skills_digit_ribbon">
                                                      <span className="sc_skills_digit_value">
                                                        0
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                                <span className="sc_skills_digit">
                                                  <span className="sc_skills_digit_placeholder">
                                                    8
                                                  </span>
                                                  <span className="sc_skills_digit_wrap">
                                                    <span className="sc_skills_digit_ribbon">
                                                      <span className="sc_skills_digit_value">
                                                        0
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                                <span className="sc_skills_digit">
                                                  <span className="sc_skills_digit_placeholder">
                                                    8
                                                  </span>
                                                  <span className="sc_skills_digit_wrap">
                                                    <span className="sc_skills_digit_ribbon">
                                                      <span className="sc_skills_digit_value">
                                                        0
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                                <span className="sc_skills_digit">
                                                  <span className="sc_skills_digit_placeholder">
                                                    8
                                                  </span>
                                                  <span className="sc_skills_digit_wrap">
                                                    <span className="sc_skills_digit_ribbon">
                                                      <span className="sc_skills_digit_value">
                                                        0
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="sc_skills_column trx_addons_column-1_4 trx_addons_column-1_2-mobile">
                                        <div className="sc_skills_item_wrap sc_skills_item_icon_position_top">
                                          <div className="sc_skills_item_title">
                                            Years
                                          </div>
                                          <div className="sc_skills_item">
                                            <div
                                              className="sc_skills_total"
                                              data-start={0}
                                              data-stop={25}
                                              data-step="0.25"
                                              data-max={2548}
                                              data-speed={15}
                                              data-duration={1500}
                                              data-ed="+"
                                              data-style="odometer"
                                            >
                                              <span className="sc_skills_digits">
                                                <span className="sc_skills_digit">
                                                  <span className="sc_skills_digit_placeholder">
                                                    8
                                                  </span>
                                                  <span className="sc_skills_digit_wrap">
                                                    <span className="sc_skills_digit_ribbon">
                                                      <span className="sc_skills_digit_value">
                                                        0
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                                <span className="sc_skills_digit">
                                                  <span className="sc_skills_digit_placeholder">
                                                    8
                                                  </span>
                                                  <span className="sc_skills_digit_wrap">
                                                    <span className="sc_skills_digit_ribbon">
                                                      <span className="sc_skills_digit_value">
                                                        0
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                                <span className="sc_skills_unit">
                                                  +
                                                </span>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="sc_skills_column trx_addons_column-1_4 trx_addons_column-1_2-mobile">
                                        <div className="sc_skills_item_wrap sc_skills_item_icon_position_top">
                                          <div className="sc_skills_item_title">
                                            Trophies
                                          </div>
                                          <div className="sc_skills_item">
                                            <div
                                              className="sc_skills_total"
                                              data-start={0}
                                              data-stop={256}
                                              data-step="2.56"
                                              data-max={2548}
                                              data-speed={15}
                                              data-duration={1500}
                                              data-ed=""
                                              data-style="odometer"
                                            >
                                              <span className="sc_skills_digits">
                                                <span className="sc_skills_digit">
                                                  <span className="sc_skills_digit_placeholder">
                                                    8
                                                  </span>
                                                  <span className="sc_skills_digit_wrap">
                                                    <span className="sc_skills_digit_ribbon">
                                                      <span className="sc_skills_digit_value">
                                                        0
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                                <span className="sc_skills_digit">
                                                  <span className="sc_skills_digit_placeholder">
                                                    8
                                                  </span>
                                                  <span className="sc_skills_digit_wrap">
                                                    <span className="sc_skills_digit_ribbon">
                                                      <span className="sc_skills_digit_value">
                                                        0
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                                <span className="sc_skills_digit">
                                                  <span className="sc_skills_digit_placeholder">
                                                    8
                                                  </span>
                                                  <span className="sc_skills_digit_wrap">
                                                    <span className="sc_skills_digit_ribbon">
                                                      <span className="sc_skills_digit_value">
                                                        0
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>{" "}
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-7e88433 sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer"
                                data-id="7e88433"
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section
                        data-mouse-helper="hover"
                        data-mouse-helper-hide-helper={1}
                        data-mouse-helper-centered={1}
                        data-mouse-helper-magnet={0}
                        data-mouse-helper-color=""
                        data-mouse-helper-bg-color="#FF5B4A"
                        data-mouse-helper-bd-color=""
                        data-mouse-helper-bd-width={-1}
                        data-mouse-helper-mode="multiply"
                        data-mouse-helper-axis="xy"
                        data-mouse-helper-delay={0}
                        data-mouse-helper-text=""
                        data-mouse-helper-text-size=""
                        data-mouse-helper-text-round={0}
                        data-mouse-helper-icon=""
                        data-mouse-helper-icon-size=""
                        data-mouse-helper-icon-color=""
                        data-mouse-helper-image=""
                        data-mouse-helper-layout=""
                        data-mouse-helper-class=""
                        data-mouse-helper-callback=""
                        data-mouse-helper-hide-cursor={0}
                        className="elementor-section elementor-top-section elementor-element elementor-element-db214c6 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default animation_type_block sc_fly_static elementor-invisible"
                        data-id="db214c6"
                        data-element_type="section"
                        data-settings='{"background_background":"classic","animation":"spin-fadein"}'
                      >
                        <div className="elementor-background-overlay" />
                        <div className="elementor-container elementor-column-gap-default">
                          <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3f86b24 sc_layouts_column_align_center sc_layouts_column sc-mobile_layouts_column_align_center sc_layouts_column sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-id="3f86b24"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-cb1efc2 sc_fly_static elementor-widget elementor-widget-spacer"
                                data-id="cb1efc2"
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                  </div>
                                </div>
                              </div>
                              <div
                                data-mouse-helper="hover"
                                data-mouse-helper-hide-helper={1}
                                data-mouse-helper-centered={1}
                                data-mouse-helper-magnet={99}
                                data-mouse-helper-color=""
                                data-mouse-helper-bg-color="#FB2610"
                                data-mouse-helper-bd-color=""
                                data-mouse-helper-bd-width={-1}
                                data-mouse-helper-mode="multiply"
                                data-mouse-helper-axis="xy"
                                data-mouse-helper-delay={0}
                                data-mouse-helper-text=""
                                data-mouse-helper-text-size=""
                                data-mouse-helper-text-round={0}
                                data-mouse-helper-icon=""
                                data-mouse-helper-icon-size=""
                                data-mouse-helper-icon-color=""
                                data-mouse-helper-image=""
                                data-mouse-helper-layout=""
                                data-mouse-helper-class=""
                                data-mouse-helper-callback=""
                                data-mouse-helper-hide-cursor={0}
                                className="elementor-element elementor-element-e208761 elementor-align-center round-square elementor-widget__width-auto sc_fly_static elementor-widget elementor-widget-button"
                                data-id="e208761"
                                data-element_type="widget"
                                data-widget_type="button.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-button-wrapper">
                                    <a
                                      className="elementor-button elementor-button-link elementor-size-sm"
                                      href="#go-video"
                                    >
                                      <span className="elementor-button-content-wrapper">
                                        <span className="elementor-button-text">
                                          PLAY
                                        </span>
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-cf27cfa sc_fly_static elementor-widget elementor-widget-spacer"
                                data-id="cf27cfa"
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section
                        className="elementor-section elementor-top-section elementor-element elementor-element-6b8f2b1 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                        data-id="6b8f2b1"
                        data-element_type="section"
                        data-settings='{"background_background":"classic"}'
                      >
                        <div className="elementor-container elementor-column-gap-extended">
                          <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b8b65b6 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-id="b8b65b6"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-d619e94 sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer"
                                data-id="d619e94"
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-8a980fb animation_type_block sc_fly_static elementor-invisible elementor-widget elementor-widget-trx_sc_title"
                                data-id="8a980fb"
                                data-element_type="widget"
                                data-settings='{"_animation":"spin-fadein"}'
                                data-widget_type="trx_sc_title.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="sc_title sc_title_default">
                                    <span className="sc_item_subtitle sc_title_subtitle sc_align_center sc_item_subtitle_above sc_item_title_style_default">
                                      PLAYERS
                                    </span>
                                    <h1 className="sc_item_title sc_title_title sc_align_center sc_item_title_style_default sc_item_title_tag">
                                      <span className="sc_item_title_text">
                                        Our main team
                                      </span>
                                    </h1>
                                  </div>{" "}
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-1a8f16e sc_height_small sc_fly_static elementor-widget elementor-widget-spacer"
                                data-id="1a8f16e"
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-6859e74 animation_type_block sc_fly_static elementor-invisible elementor-widget elementor-widget-trx_sc_team"
                                data-id="6859e74"
                                data-element_type="widget"
                                data-settings='{"_animation":"fadeIn"}'
                                data-widget_type="trx_sc_team.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="sc_team sc_team_short">
                                    <div className="sc_team_slider sc_item_slider slider_swiper_outer slider_outer slider_outer_nocontrols slider_outer_pagination slider_outer_pagination_progressbar slider_outer_pagination_pos_bottom slider_outer_nocentered slider_outer_overflow_hidden slider_outer_multi">
                                      <div
                                        data-slides-per-view-breakpoints='{"999999":5}'
                                        className="slider_container swiper-slider-container slider_swiper slider_noresize slider_nocontrols slider_pagination slider_pagination_progressbar slider_pagination_pos_bottom slider_nocentered slider_overflow_hidden slider_multi"
                                        data-slides-per-view={5}
                                        data-slides-space={30}
                                        data-effect="slide"
                                        data-slides-min-width={220}
                                        data-pagination="progressbar"
                                        data-direction="horizontal"
                                        data-mouse-wheel={0}
                                        data-autoplay={1}
                                        data-loop={1}
                                        data-free-mode={0}
                                        data-slides-centered={0}
                                        data-slides-overflow={0}
                                      >
                                        <div className="slides slider-wrapper swiper-wrapper sc_item_columns_5">
                                          <div className="slider-slide swiper-slide">
                                            <div
                                              data-post-id={28129}
                                              className="sc_team_item sc_item_container post_container no_links post-28129 sp_player type-sp_player status-publish has-post-thumbnail hentry sp_league-premier-league sp_league-secondary-league sp_season-278 sp_season-266 sp_season-267 sp_position-wicketkeeper"
                                            >
                                              <div className="post_featured sc_team_item_thumb trx_addons_hover trx_addons_hover_style_info_anim">
                                                <img
                                                  loading="lazy"
                                                  decoding="async"
                                                  width={570}
                                                  height={696}
                                                  src="https://spin.axiomthemes.com/wp-content/uploads/2023/09/1-copyright-570x696.jpg"
                                                  className="attachment-spin-thumb-rectangle size-spin-thumb-rectangle wp-post-image"
                                                  alt=""
                                                  srcSet="https://spin.axiomthemes.com/wp-content/uploads/2023/09/1-copyright-570x696.jpg 570w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/1-copyright-25x32.jpg 25w"
                                                  sizes="(max-width: 600px) 100vw, 570px"
                                                />
                                                <span className="sp-player-number">
                                                  9
                                                </span>
                                                <a
                                                  className="post_link sc_team_item_link"
                                                  href="https://spin.axiomthemes.com/player/marcus-lawson/"
                                                />
                                                <div className="trx_addons_hover_mask" />
                                              </div>{" "}
                                              <div className="sc_team_item_info">
                                                <div className="sc_team_item_header">
                                                  <h4 className="sc_team_item_title entry-title">
                                                    <a href="https://spin.axiomthemes.com/player/marcus-lawson/">
                                                      Marcus Lawson
                                                    </a>
                                                  </h4>
                                                  <div className="sc_team_item_subtitle">
                                                    Wicketkeeper
                                                  </div>{" "}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="slider-slide swiper-slide">
                                            <div
                                              data-post-id={28202}
                                              className="sc_team_item sc_item_container post_container no_links post-28202 sp_player type-sp_player status-publish has-post-thumbnail hentry sp_league-premier-league sp_league-secondary-league sp_season-278 sp_season-266 sp_season-267 sp_position-wicketkeeper"
                                            >
                                              <div className="post_featured sc_team_item_thumb trx_addons_hover trx_addons_hover_style_info_anim">
                                                <img
                                                  loading="lazy"
                                                  decoding="async"
                                                  width={570}
                                                  height={696}
                                                  src="https://spin.axiomthemes.com/wp-content/uploads/2023/09/2-copyright-570x696.jpg"
                                                  className="attachment-spin-thumb-rectangle size-spin-thumb-rectangle wp-post-image"
                                                  alt=""
                                                  srcSet="https://spin.axiomthemes.com/wp-content/uploads/2023/09/2-copyright-570x696.jpg 570w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/2-copyright-25x32.jpg 25w"
                                                  sizes="(max-width: 600px) 100vw, 570px"
                                                />
                                                <span className="sp-player-number">
                                                  2
                                                </span>
                                                <a
                                                  className="post_link sc_team_item_link"
                                                  href="https://spin.axiomthemes.com/player/caleb-reynolds/"
                                                />
                                                <div className="trx_addons_hover_mask" />
                                              </div>{" "}
                                              <div className="sc_team_item_info">
                                                <div className="sc_team_item_header">
                                                  <h4 className="sc_team_item_title entry-title">
                                                    <a href="https://spin.axiomthemes.com/player/caleb-reynolds/">
                                                      Caleb Reynolds
                                                    </a>
                                                  </h4>
                                                  <div className="sc_team_item_subtitle">
                                                    Wicketkeeper
                                                  </div>{" "}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="slider-slide swiper-slide">
                                            <div
                                              data-post-id={28128}
                                              className="sc_team_item sc_item_container post_container no_links post-28128 sp_player type-sp_player status-publish has-post-thumbnail hentry sp_league-premier-league sp_league-secondary-league sp_season-278 sp_season-266 sp_season-267 sp_position-bowler"
                                            >
                                              <div className="post_featured sc_team_item_thumb trx_addons_hover trx_addons_hover_style_info_anim">
                                                <img
                                                  loading="lazy"
                                                  decoding="async"
                                                  width={570}
                                                  height={696}
                                                  src="https://spin.axiomthemes.com/wp-content/uploads/2023/09/4-copyright-570x696.jpg"
                                                  className="attachment-spin-thumb-rectangle size-spin-thumb-rectangle wp-post-image"
                                                  alt=""
                                                  srcSet="https://spin.axiomthemes.com/wp-content/uploads/2023/09/4-copyright-570x696.jpg 570w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/4-copyright-25x32.jpg 25w"
                                                  sizes="(max-width: 600px) 100vw, 570px"
                                                />
                                                <span className="sp-player-number">
                                                  3
                                                </span>
                                                <a
                                                  className="post_link sc_team_item_link"
                                                  href="https://spin.axiomthemes.com/player/silas-grant/"
                                                />
                                                <div className="trx_addons_hover_mask" />
                                              </div>{" "}
                                              <div className="sc_team_item_info">
                                                <div className="sc_team_item_header">
                                                  <h4 className="sc_team_item_title entry-title">
                                                    <a href="https://spin.axiomthemes.com/player/silas-grant/">
                                                      Silas Grant
                                                    </a>
                                                  </h4>
                                                  <div className="sc_team_item_subtitle">
                                                    Bowler
                                                  </div>{" "}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="slider-slide swiper-slide">
                                            <div
                                              data-post-id={28121}
                                              className="sc_team_item sc_item_container post_container no_links post-28121 sp_player type-sp_player status-publish has-post-thumbnail hentry sp_league-premier-league sp_league-secondary-league sp_season-278 sp_season-266 sp_season-267 sp_position-bowler"
                                            >
                                              <div className="post_featured sc_team_item_thumb trx_addons_hover trx_addons_hover_style_info_anim">
                                                <img
                                                  loading="lazy"
                                                  decoding="async"
                                                  width={570}
                                                  height={696}
                                                  src="https://spin.axiomthemes.com/wp-content/uploads/2023/09/5-copyright-570x696.jpg"
                                                  className="attachment-spin-thumb-rectangle size-spin-thumb-rectangle wp-post-image"
                                                  alt=""
                                                  srcSet="https://spin.axiomthemes.com/wp-content/uploads/2023/09/5-copyright-570x696.jpg 570w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/5-copyright-25x32.jpg 25w"
                                                  sizes="(max-width: 600px) 100vw, 570px"
                                                />
                                                <span className="sp-player-number">
                                                  1
                                                </span>
                                                <a
                                                  className="post_link sc_team_item_link"
                                                  href="https://spin.axiomthemes.com/player/elliott-sullivan/"
                                                />
                                                <div className="trx_addons_hover_mask" />
                                              </div>{" "}
                                              <div className="sc_team_item_info">
                                                <div className="sc_team_item_header">
                                                  <h4 className="sc_team_item_title entry-title">
                                                    <a href="https://spin.axiomthemes.com/player/elliott-sullivan/">
                                                      Elliott Sullivan
                                                    </a>
                                                  </h4>
                                                  <div className="sc_team_item_subtitle">
                                                    Bowler
                                                  </div>{" "}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="slider-slide swiper-slide">
                                            <div
                                              data-post-id={28126}
                                              className="sc_team_item sc_item_container post_container no_links post-28126 sp_player type-sp_player status-publish has-post-thumbnail hentry sp_league-premier-league sp_league-secondary-league sp_season-278 sp_season-266 sp_season-267 sp_position-batsman"
                                            >
                                              <div className="post_featured sc_team_item_thumb trx_addons_hover trx_addons_hover_style_info_anim">
                                                <img
                                                  loading="lazy"
                                                  decoding="async"
                                                  width={570}
                                                  height={696}
                                                  src="https://spin.axiomthemes.com/wp-content/uploads/2023/09/3-copyright-570x696.jpg"
                                                  className="attachment-spin-thumb-rectangle size-spin-thumb-rectangle wp-post-image"
                                                  alt=""
                                                  srcSet="https://spin.axiomthemes.com/wp-content/uploads/2023/09/3-copyright-570x696.jpg 570w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/3-copyright-25x32.jpg 25w"
                                                  sizes="(max-width: 600px) 100vw, 570px"
                                                />
                                                <span className="sp-player-number">
                                                  5
                                                </span>
                                                <a
                                                  className="post_link sc_team_item_link"
                                                  href="https://spin.axiomthemes.com/player/elijah-thornton/"
                                                />
                                                <div className="trx_addons_hover_mask" />
                                              </div>{" "}
                                              <div className="sc_team_item_info">
                                                <div className="sc_team_item_header">
                                                  <h4 className="sc_team_item_title entry-title">
                                                    <a href="https://spin.axiomthemes.com/player/elijah-thornton/">
                                                      Elijah Thornton
                                                    </a>
                                                  </h4>
                                                  <div className="sc_team_item_subtitle">
                                                    Batsman
                                                  </div>{" "}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="slider-slide swiper-slide">
                                            <div
                                              data-post-id={28199}
                                              className="sc_team_item sc_item_container post_container no_links post-28199 sp_player type-sp_player status-publish has-post-thumbnail hentry sp_league-premier-league sp_league-secondary-league sp_season-278 sp_season-266 sp_season-267 sp_position-bowler"
                                            >
                                              <div className="post_featured sc_team_item_thumb trx_addons_hover trx_addons_hover_style_info_anim">
                                                <img
                                                  loading="lazy"
                                                  decoding="async"
                                                  width={570}
                                                  height={696}
                                                  src="https://spin.axiomthemes.com/wp-content/uploads/2023/09/6-copyright-570x696.jpg"
                                                  className="attachment-spin-thumb-rectangle size-spin-thumb-rectangle wp-post-image"
                                                  alt=""
                                                  srcSet="https://spin.axiomthemes.com/wp-content/uploads/2023/09/6-copyright-570x696.jpg 570w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/6-copyright-25x32.jpg 25w"
                                                  sizes="(max-width: 600px) 100vw, 570px"
                                                />
                                                <span className="sp-player-number">
                                                  4
                                                </span>
                                                <a
                                                  className="post_link sc_team_item_link"
                                                  href="https://spin.axiomthemes.com/player/nicholas-crawford/"
                                                />
                                                <div className="trx_addons_hover_mask" />
                                              </div>{" "}
                                              <div className="sc_team_item_info">
                                                <div className="sc_team_item_header">
                                                  <h4 className="sc_team_item_title entry-title">
                                                    <a href="https://spin.axiomthemes.com/player/nicholas-crawford/">
                                                      Nicholas Crawford
                                                    </a>
                                                  </h4>
                                                  <div className="sc_team_item_subtitle">
                                                    Bowler
                                                  </div>{" "}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="slider-slide swiper-slide">
                                            <div
                                              data-post-id={28044}
                                              className="sc_team_item sc_item_container post_container no_links post-28044 sp_player type-sp_player status-publish has-post-thumbnail hentry sp_league-premier-league sp_season-266 sp_season-267 sp_position-wicketkeeper"
                                            >
                                              <div className="post_featured sc_team_item_thumb trx_addons_hover trx_addons_hover_style_info_anim">
                                                <img
                                                  loading="lazy"
                                                  decoding="async"
                                                  width={570}
                                                  height={696}
                                                  src="https://spin.axiomthemes.com/wp-content/uploads/2023/09/7-copyright-570x696.jpg"
                                                  className="attachment-spin-thumb-rectangle size-spin-thumb-rectangle wp-post-image"
                                                  alt=""
                                                  srcSet="https://spin.axiomthemes.com/wp-content/uploads/2023/09/7-copyright-570x696.jpg 570w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/7-copyright-25x32.jpg 25w"
                                                  sizes="(max-width: 600px) 100vw, 570px"
                                                />
                                                <span className="sp-player-number">
                                                  6
                                                </span>
                                                <a
                                                  className="post_link sc_team_item_link"
                                                  href="https://spin.axiomthemes.com/player/jeetan-patel/"
                                                />
                                                <div className="trx_addons_hover_mask" />
                                              </div>{" "}
                                              <div className="sc_team_item_info">
                                                <div className="sc_team_item_header">
                                                  <h4 className="sc_team_item_title entry-title">
                                                    <a href="https://spin.axiomthemes.com/player/jeetan-patel/">
                                                      Jeetan Patel
                                                    </a>
                                                  </h4>
                                                  <div className="sc_team_item_subtitle">
                                                    Wicketkeeper
                                                  </div>{" "}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="slider-slide swiper-slide">
                                            <div
                                              data-post-id={28043}
                                              className="sc_team_item sc_item_container post_container no_links post-28043 sp_player type-sp_player status-publish has-post-thumbnail hentry sp_league-premier-league sp_season-266 sp_season-267 sp_position-bowler"
                                            >
                                              <div className="post_featured sc_team_item_thumb trx_addons_hover trx_addons_hover_style_info_anim">
                                                <img
                                                  loading="lazy"
                                                  decoding="async"
                                                  width={570}
                                                  height={696}
                                                  src="https://spin.axiomthemes.com/wp-content/uploads/2023/09/8-copyright-570x696.jpg"
                                                  className="attachment-spin-thumb-rectangle size-spin-thumb-rectangle wp-post-image"
                                                  alt=""
                                                  srcSet="https://spin.axiomthemes.com/wp-content/uploads/2023/09/8-copyright-570x696.jpg 570w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/8-copyright-25x32.jpg 25w"
                                                  sizes="(max-width: 600px) 100vw, 570px"
                                                />
                                                <span className="sp-player-number">
                                                  11
                                                </span>
                                                <a
                                                  className="post_link sc_team_item_link"
                                                  href="https://spin.axiomthemes.com/player/paul-collingwood/"
                                                />
                                                <div className="trx_addons_hover_mask" />
                                              </div>{" "}
                                              <div className="sc_team_item_info">
                                                <div className="sc_team_item_header">
                                                  <h4 className="sc_team_item_title entry-title">
                                                    <a href="https://spin.axiomthemes.com/player/paul-collingwood/">
                                                      Paul Collingwood
                                                    </a>
                                                  </h4>
                                                  <div className="sc_team_item_subtitle">
                                                    Bowler
                                                  </div>{" "}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="slider-slide swiper-slide">
                                            <div
                                              data-post-id={28042}
                                              className="sc_team_item sc_item_container post_container no_links post-28042 sp_player type-sp_player status-publish has-post-thumbnail hentry sp_league-premier-league sp_season-266 sp_season-267 sp_position-batsman"
                                            >
                                              <div className="post_featured sc_team_item_thumb trx_addons_hover trx_addons_hover_style_info_anim">
                                                <img
                                                  loading="lazy"
                                                  decoding="async"
                                                  width={570}
                                                  height={696}
                                                  src="https://spin.axiomthemes.com/wp-content/uploads/2023/09/9-copyright-570x696.jpg"
                                                  className="attachment-spin-thumb-rectangle size-spin-thumb-rectangle wp-post-image"
                                                  alt=""
                                                  srcSet="https://spin.axiomthemes.com/wp-content/uploads/2023/09/9-copyright-570x696.jpg 570w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/9-copyright-25x32.jpg 25w"
                                                  sizes="(max-width: 600px) 100vw, 570px"
                                                />
                                                <span className="sp-player-number">
                                                  6
                                                </span>
                                                <a
                                                  className="post_link sc_team_item_link"
                                                  href="https://spin.axiomthemes.com/player/brendon-mccullum/"
                                                />
                                                <div className="trx_addons_hover_mask" />
                                              </div>{" "}
                                              <div className="sc_team_item_info">
                                                <div className="sc_team_item_header">
                                                  <h4 className="sc_team_item_title entry-title">
                                                    <a href="https://spin.axiomthemes.com/player/brendon-mccullum/">
                                                      Brendon McCullum
                                                    </a>
                                                  </h4>
                                                  <div className="sc_team_item_subtitle">
                                                    Batsman
                                                  </div>{" "}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="slider-slide swiper-slide">
                                            <div
                                              data-post-id={28127}
                                              className="sc_team_item sc_item_container post_container no_links post-28127 sp_player type-sp_player status-publish has-post-thumbnail hentry sp_league-premier-league sp_league-secondary-league sp_season-278 sp_season-266 sp_season-267 sp_position-bowler"
                                            >
                                              <div className="post_featured sc_team_item_thumb trx_addons_hover trx_addons_hover_style_info_anim">
                                                <img
                                                  loading="lazy"
                                                  decoding="async"
                                                  width={570}
                                                  height={696}
                                                  src="https://spin.axiomthemes.com/wp-content/uploads/2023/09/10-copyright-570x696.jpg"
                                                  className="attachment-spin-thumb-rectangle size-spin-thumb-rectangle wp-post-image"
                                                  alt=""
                                                  srcSet="https://spin.axiomthemes.com/wp-content/uploads/2023/09/10-copyright-570x696.jpg 570w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/10-copyright-25x32.jpg 25w"
                                                  sizes="(max-width: 600px) 100vw, 570px"
                                                />
                                                <span className="sp-player-number">
                                                  7
                                                </span>
                                                <a
                                                  className="post_link sc_team_item_link"
                                                  href="https://spin.axiomthemes.com/player/wesley-simmons/"
                                                />
                                                <div className="trx_addons_hover_mask" />
                                              </div>{" "}
                                              <div className="sc_team_item_info">
                                                <div className="sc_team_item_header">
                                                  <h4 className="sc_team_item_title entry-title">
                                                    <a href="https://spin.axiomthemes.com/player/wesley-simmons/">
                                                      Wesley Simmons
                                                    </a>
                                                  </h4>
                                                  <div className="sc_team_item_subtitle">
                                                    Bowler
                                                  </div>{" "}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="slider-slide swiper-slide">
                                            <div
                                              data-post-id={28125}
                                              className="sc_team_item sc_item_container post_container no_links post-28125 sp_player type-sp_player status-publish has-post-thumbnail hentry sp_league-premier-league sp_league-secondary-league sp_season-278 sp_season-266 sp_season-267 sp_position-captain"
                                            >
                                              <div className="post_featured sc_team_item_thumb trx_addons_hover trx_addons_hover_style_info_anim">
                                                <img
                                                  loading="lazy"
                                                  decoding="async"
                                                  width={570}
                                                  height={696}
                                                  src="https://spin.axiomthemes.com/wp-content/uploads/2023/09/11-copyright-570x696.jpg"
                                                  className="attachment-spin-thumb-rectangle size-spin-thumb-rectangle wp-post-image"
                                                  alt=""
                                                  srcSet="https://spin.axiomthemes.com/wp-content/uploads/2023/09/11-copyright-570x696.jpg 570w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/11-copyright-25x32.jpg 25w"
                                                  sizes="(max-width: 600px) 100vw, 570px"
                                                />
                                                <span className="sp-player-number">
                                                  10
                                                </span>
                                                <a
                                                  className="post_link sc_team_item_link"
                                                  href="https://spin.axiomthemes.com/player/aiden-foster/"
                                                />
                                                <div className="trx_addons_hover_mask" />
                                              </div>{" "}
                                              <div className="sc_team_item_info">
                                                <div className="sc_team_item_header">
                                                  <h4 className="sc_team_item_title entry-title">
                                                    <a href="https://spin.axiomthemes.com/player/aiden-foster/">
                                                      Aiden Foster
                                                    </a>
                                                  </h4>
                                                  <div className="sc_team_item_subtitle">
                                                    Captain
                                                  </div>{" "}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="slider_pagination_wrap swiper-pagination" />
                                    </div>
                                  </div>{" "}
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-fe10094 sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer"
                                data-id="fe10094"
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>



                      <section
                        className="elementor-section elementor-top-section elementor-element elementor-element-486ae5f scheme_light elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                        data-id="486ae5f"
                        data-element_type="section"
                        data-settings='{"background_background":"classic"}'
                      >
                        <div className="elementor-container elementor-column-gap-extended">
                          <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c5120bb sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-id="c5120bb"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-03c8652 sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer"
                                data-id="03c8652"
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-68bcfd2 animation_type_block sc_fly_static elementor-invisible elementor-widget elementor-widget-trx_sc_title"
                                data-id="68bcfd2"
                                data-element_type="widget"
                                data-settings='{"_animation":"spin-fadein"}'
                                data-widget_type="trx_sc_title.default"
                              >

                              </div>

                              <div
                                className="elementor-element elementor-element-0eece5b sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer"
                                data-id="0eece5b"
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>





                      <section
                        className="elementor-section elementor-top-section elementor-element elementor-element-be0de5f elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                        data-id="be0de5f"
                        data-element_type="section"
                      >
                        <div className="elementor-container elementor-column-gap-extended">
                          <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-8848f29 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-id="8848f29"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-bf871ff sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer"
                                data-id="bf871ff"
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section
                        className="elementor-section elementor-top-section elementor-element elementor-element-e8111c1 elementor-section-content-middle animation_type_sequental elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static elementor-invisible"
                        data-id="e8111c1"
                        data-element_type="section"
                        data-settings='{"animation":"spin-fadeinup","animation_delay":100}'
                      >
                        <div className="elementor-container elementor-column-gap-extended">
                          <div
                            className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-5743b5e sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-id="5743b5e"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-8086fbc sc_fly_static elementor-widget elementor-widget-image"
                                data-id="8086fbc"
                                data-element_type="widget"
                                data-widget_type="image.default"
                              >
                                <div className="elementor-widget-container">
                                  <img
                                    loading="lazy"
                                    decoding="async"
                                    width={600}
                                    height={234}
                                    src="https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-1-copyright.png"
                                    className="attachment-full size-full wp-image-27861"
                                    alt=""
                                    srcSet="https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-1-copyright.png 600w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-1-copyright-300x117.png 300w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-1-copyright-370x144.png 370w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-1-copyright-410x160.png 410w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-1-copyright-500x195.png 500w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-1-copyright-400x156.png 400w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-1-copyright-32x12.png 32w"
                                    sizes="(max-width: 600px) 100vw, 600px"
                                  />{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-327311a sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-id="327311a"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-b231dba sc_fly_static elementor-widget elementor-widget-image"
                                data-id="b231dba"
                                data-element_type="widget"
                                data-widget_type="image.default"
                              >
                                <div className="elementor-widget-container">
                                  <img
                                    loading="lazy"
                                    decoding="async"
                                    width={600}
                                    height={234}
                                    src="https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-2-copyright.png"
                                    className="attachment-full size-full wp-image-27862"
                                    alt=""
                                    srcSet="https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-2-copyright.png 600w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-2-copyright-300x117.png 300w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-2-copyright-370x144.png 370w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-2-copyright-410x160.png 410w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-2-copyright-500x195.png 500w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-2-copyright-400x156.png 400w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-2-copyright-32x12.png 32w"
                                    sizes="(max-width: 600px) 100vw, 600px"
                                  />{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-7b9c35c sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-id="7b9c35c"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-d39c438 sc_fly_static elementor-widget elementor-widget-image"
                                data-id="d39c438"
                                data-element_type="widget"
                                data-widget_type="image.default"
                              >
                                <div className="elementor-widget-container">
                                  <img
                                    loading="lazy"
                                    decoding="async"
                                    width={600}
                                    height={234}
                                    src="https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-3-copyright.png"
                                    className="attachment-full size-full wp-image-27863"
                                    alt=""
                                    srcSet="https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-3-copyright.png 600w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-3-copyright-300x117.png 300w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-3-copyright-370x144.png 370w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-3-copyright-410x160.png 410w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-3-copyright-500x195.png 500w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-3-copyright-400x156.png 400w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-3-copyright-32x12.png 32w"
                                    sizes="(max-width: 600px) 100vw, 600px"
                                  />{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-d2b5fbf sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-id="d2b5fbf"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-113cf48 sc_fly_static elementor-widget elementor-widget-image"
                                data-id="113cf48"
                                data-element_type="widget"
                                data-widget_type="image.default"
                              >
                                <div className="elementor-widget-container">
                                  <img
                                    loading="lazy"
                                    decoding="async"
                                    width={600}
                                    height={234}
                                    src="https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-4-copyright.png"
                                    className="attachment-full size-full wp-image-27864"
                                    alt=""
                                    srcSet="https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-4-copyright.png 600w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-4-copyright-300x117.png 300w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-4-copyright-370x144.png 370w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-4-copyright-410x160.png 410w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-4-copyright-500x195.png 500w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-4-copyright-400x156.png 400w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-4-copyright-32x12.png 32w"
                                    sizes="(max-width: 600px) 100vw, 600px"
                                  />{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section
                        className="elementor-section elementor-top-section elementor-element elementor-element-3b3b627 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                        data-id="3b3b627"
                        data-element_type="section"
                      >
                        <div className="elementor-container elementor-column-gap-extended">
                          <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-0714f4d sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-id="0714f4d"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-82a622d sc_fly_static elementor-widget elementor-widget-spacer"
                                data-id="82a622d"
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section
                        className="elementor-section elementor-top-section elementor-element elementor-element-0e337c2 elementor-section-content-middle animation_type_sequental elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static elementor-invisible"
                        data-id="0e337c2"
                        data-element_type="section"
                        data-settings='{"animation":"spin-fadeinup","animation_delay":100}'
                      >
                        <div className="elementor-container elementor-column-gap-extended">
                          <div
                            className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-2c99786 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-id="2c99786"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-ece757e sc_fly_static elementor-widget elementor-widget-image"
                                data-id="ece757e"
                                data-element_type="widget"
                                data-widget_type="image.default"
                              >
                                <div className="elementor-widget-container">
                                  <img
                                    loading="lazy"
                                    decoding="async"
                                    width={600}
                                    height={234}
                                    src="https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-5-copyright.png"
                                    className="attachment-full size-full wp-image-27865"
                                    alt=""
                                    srcSet="https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-5-copyright.png 600w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-5-copyright-300x117.png 300w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-5-copyright-370x144.png 370w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-5-copyright-410x160.png 410w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-5-copyright-500x195.png 500w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-5-copyright-400x156.png 400w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-5-copyright-32x12.png 32w"
                                    sizes="(max-width: 600px) 100vw, 600px"
                                  />{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-d17636d sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-id="d17636d"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-6f0c780 sc_fly_static elementor-widget elementor-widget-image"
                                data-id="6f0c780"
                                data-element_type="widget"
                                data-widget_type="image.default"
                              >
                                <div className="elementor-widget-container">
                                  <img
                                    loading="lazy"
                                    decoding="async"
                                    width={600}
                                    height={234}
                                    src="https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-6-copyright.png"
                                    className="attachment-full size-full wp-image-27866"
                                    alt=""
                                    srcSet="https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-6-copyright.png 600w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-6-copyright-300x117.png 300w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-6-copyright-370x144.png 370w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-6-copyright-410x160.png 410w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-6-copyright-500x195.png 500w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-6-copyright-400x156.png 400w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-6-copyright-32x12.png 32w"
                                    sizes="(max-width: 600px) 100vw, 600px"
                                  />{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-b3e23e6 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-id="b3e23e6"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-38039e4 sc_fly_static elementor-widget elementor-widget-image"
                                data-id="38039e4"
                                data-element_type="widget"
                                data-widget_type="image.default"
                              >
                                <div className="elementor-widget-container">
                                  <img
                                    loading="lazy"
                                    decoding="async"
                                    width={600}
                                    height={234}
                                    src="https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-7-copyright.png"
                                    className="attachment-full size-full wp-image-27867"
                                    alt=""
                                    srcSet="https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-7-copyright.png 600w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-7-copyright-300x117.png 300w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-7-copyright-370x144.png 370w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-7-copyright-410x160.png 410w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-7-copyright-500x195.png 500w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-7-copyright-400x156.png 400w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-7-copyright-32x12.png 32w"
                                    sizes="(max-width: 600px) 100vw, 600px"
                                  />{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-f0937cc sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-id="f0937cc"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-c84d798 sc_fly_static elementor-widget elementor-widget-image"
                                data-id="c84d798"
                                data-element_type="widget"
                                data-widget_type="image.default"
                              >
                                <div className="elementor-widget-container">
                                  <img
                                    loading="lazy"
                                    decoding="async"
                                    width={600}
                                    height={234}
                                    src="https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-8-copyright.png"
                                    className="attachment-full size-full wp-image-27868"
                                    alt=""
                                    srcSet="https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-8-copyright.png 600w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-8-copyright-300x117.png 300w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-8-copyright-370x144.png 370w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-8-copyright-410x160.png 410w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-8-copyright-500x195.png 500w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-8-copyright-400x156.png 400w, https://spin.axiomthemes.com/wp-content/uploads/2023/09/plate-partners-8-copyright-32x12.png 32w"
                                    sizes="(max-width: 600px) 100vw, 600px"
                                  />{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section
                        className="elementor-section elementor-top-section elementor-element elementor-element-4f32361 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                        data-id="4f32361"
                        data-element_type="section"
                      >
                        <div className="elementor-container elementor-column-gap-extended">
                          <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9b7f4f7 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-id="9b7f4f7"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-4379225 sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer"
                                data-id={4379225}
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>



                      <section
                        className="elementor-section elementor-top-section elementor-element elementor-element-9250ba8 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                        data-id="9250ba8"
                        data-element_type="section"
                        data-settings='{"background_background":"classic"}'
                      >
                        <div className="elementor-background-overlay" />
                        <div className="elementor-container elementor-column-gap-extended">
                          <div
                            className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-02aa043 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-id="02aa043"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-2e27243 sc_fly_static elementor-widget elementor-widget-spacer"
                                data-id="2e27243"
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-057fb97 animation_type_block sc_fly_static elementor-invisible elementor-widget elementor-widget-trx_sc_title"
                                data-id="057fb97"
                                data-element_type="widget"
                                data-settings='{"_animation":"spin-fadeinright"}'
                                data-widget_type="trx_sc_title.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="sc_title sc_title_default">
                                    <span className="sc_item_subtitle sc_title_subtitle sc_item_subtitle_above sc_item_title_style_default">
                                      welcome
                                    </span>
                                    <h1 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag">
                                      <span className="sc_item_title_text">
                                        Experience the true joy of professional
                                        cricket games!
                                      </span>
                                    </h1>
                                    <div className="sc_item_button sc_button_wrap sc_item_button sc_item_button_default sc_item_button_size_normal sc_title_button">
                                      <a
                                        href="/about"
                                        className="sc_button sc_button_default sc_button_size_normal sc_button_icon_left"
                                      >
                                        <span className="sc_button_text">
                                          <span className="sc_button_title">
                                            About Us
                                          </span>
                                        </span>
                                      </a>
                                    </div>
                                  </div>{" "}
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-b39ddd2 sc_fly_static elementor-widget elementor-widget-spacer"
                                data-id="b39ddd2"
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-c185455 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-id="c185455"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap"></div>
                          </div>
                        </div>
                      </section>



                      <section
                        className="elementor-section elementor-top-section elementor-element elementor-element-740fc33 hide_bg_image_on_mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                        data-id="740fc33"
                        data-element_type="section"
                        data-settings='{"background_background":"classic"}'
                      >
                        <div className="elementor-container elementor-column-gap-extended">
                          <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4716ac6 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-id="4716ac6"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-a0e9643 sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer"
                                data-id="a0e9643"
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-a6f83ef elementor-view-default sc_fly_static elementor-widget elementor-widget-icon"
                                data-id="a6f83ef"
                                data-element_type="widget"
                                data-widget_type="icon.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-icon-wrapper">
                                    <div className="elementor-icon">
                                      <i
                                        aria-hidden="true"
                                        className="fab fa-telegram"
                                      />{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-29fd289 sc_fly_static elementor-widget elementor-widget-spacer"
                                data-id="29fd289"
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-ff166c9 sc_fly_static elementor-widget elementor-widget-trx_sc_title"
                                data-id="ff166c9"
                                data-element_type="widget"
                                data-widget_type="trx_sc_title.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="sc_title sc_title_default">
                                    <h3 className="sc_item_title sc_title_title sc_align_center sc_item_title_style_default sc_item_title_tag">
                                      <span className="sc_item_title_text">
                                        Get the best blog stories <br />
                                        into your inbox!
                                      </span>
                                    </h3>
                                  </div>{" "}
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-868e8e2 sc_fly_static elementor-widget elementor-widget-spacer"
                                data-id="868e8e2"
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-4c57363 elementor-widget__width-initial sc_fly_static elementor-widget elementor-widget-shortcode"
                                data-id="4c57363"
                                data-element_type="widget"
                                data-widget_type="shortcode.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-shortcode">
                                    {/* Mailchimp for WordPress v4.9.10 - https://wordpress.org/plugins/mailchimp-for-wp/ */}
                                    <form
                                      id="style-7"
                                      className="mc4wp-form mc4wp-form-461"
                                      method="post"
                                      data-id={461}
                                      data-name="Subscribe"
                                    >
                                      <div className="mc4wp-form-fields">
                                        <input
                                          type="email"
                                          name="EMAIL"
                                          placeholder="Enter Your Email Address"
                                        />
                                        <button>Subscribe</button>
                                        <input
                                          name="i_agree_privacy_policy"
                                          defaultValue={1}
                                          required=""
                                          type="checkbox"
                                        />
                                        <label>
                                          I agree to the{" "}
                                          <a
                                            href="/privacy-policy/"
                                            target="_blank"
                                          >
                                            Privacy Policy
                                          </a>
                                          .
                                        </label>
                                      </div>
                                      <label style={{ display: "none !important" }}>
                                        Leave this field empty if you're human:{" "}
                                        <input
                                          type="text"
                                          name="_mc4wp_honeypot"
                                          defaultValue=""
                                          tabIndex={-1}
                                          autoComplete="off"
                                        />
                                      </label>
                                      <input
                                        type="hidden"
                                        name="_mc4wp_timestamp"
                                        defaultValue={1707612712}
                                      />
                                      <input
                                        type="hidden"
                                        name="_mc4wp_form_id"
                                        defaultValue={461}
                                      />
                                      <input
                                        type="hidden"
                                        name="_mc4wp_form_element_id"
                                        defaultValue="style-7"
                                      />
                                      <div className="mc4wp-response" />
                                    </form>
                                    {/* / Mailchimp for WordPress Plugin */}
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-cd0ff29 sc_fly_static elementor-widget elementor-widget-spacer"
                                data-id="cd0ff29"
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section
                        className="elementor-section elementor-top-section elementor-element elementor-element-a605069 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                        data-id="a605069"
                        data-element_type="section"
                      >
                        <div className="elementor-container elementor-column-gap-extended">
                          <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6b1d3b9 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-id="6b1d3b9"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-2d2a0f7 sc_fly_static elementor-widget elementor-widget-trx_widget_instagram"
                                data-id="2d2a0f7"
                                data-element_type="widget"
                                data-widget_type="trx_widget_instagram.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="widget_area sc_widget_instagram">
                                    <aside className="widget widget_instagram">
                                      <div className="widget_instagram_wrap widget_instagram_type_alter">
                                        <div className="widget_instagram_images widget_instagram_images_columns_6">
                                          <div className="widget_instagram_images_item_wrap trx_addons_inline_1483983530">
                                            <a
                                              href="//spin.axiomthemes.com/wp-content/uploads/2023/08/post37-copyright-370x370.jpg"
                                              title=""
                                              rel="magnific"
                                              className="widget_instagram_images_item widget_instagram_images_item_type_image"
                                            >
                                              <img
                                                loading="lazy"
                                                decoding="async"
                                                src="//spin.axiomthemes.com/wp-content/uploads/2023/08/post37-copyright-370x370.jpg"
                                                width={370}
                                                height={370}
                                                alt=""
                                              />
                                              <span className="widget_instagram_images_item_counters">
                                                <span className="widget_instagram_images_item_counter_likes trx_addons_icon-heart">
                                                  75
                                                </span>
                                                <span className="widget_instagram_images_item_counter_comments trx_addons_icon-comment">
                                                  36
                                                </span>
                                              </span>
                                            </a>
                                          </div>
                                          <div className="widget_instagram_images_item_wrap trx_addons_inline_1993270976">
                                            <a
                                              href="//spin.axiomthemes.com/wp-content/uploads/2020/05/post59-copyright-370x370.jpg"
                                              title=""
                                              rel="magnific"
                                              className="widget_instagram_images_item widget_instagram_images_item_type_image"
                                            >
                                              <img
                                                loading="lazy"
                                                decoding="async"
                                                src="//spin.axiomthemes.com/wp-content/uploads/2020/05/post59-copyright-370x370.jpg"
                                                width={370}
                                                height={370}
                                                alt=""
                                              />
                                              <span className="widget_instagram_images_item_counters">
                                                <span className="widget_instagram_images_item_counter_likes trx_addons_icon-heart">
                                                  35
                                                </span>
                                                <span className="widget_instagram_images_item_counter_comments trx_addons_icon-comment">
                                                  18
                                                </span>
                                              </span>
                                            </a>
                                          </div>
                                          <div className="widget_instagram_images_item_wrap trx_addons_inline_327655462">
                                            <a
                                              href="//spin.axiomthemes.com/wp-content/uploads/2023/08/post39-copyright-370x370.jpg"
                                              title=""
                                              rel="magnific"
                                              className="widget_instagram_images_item widget_instagram_images_item_type_image"
                                            >
                                              <img
                                                loading="lazy"
                                                decoding="async"
                                                src="//spin.axiomthemes.com/wp-content/uploads/2023/08/post39-copyright-370x370.jpg"
                                                width={370}
                                                height={370}
                                                alt=""
                                              />
                                              <span className="widget_instagram_images_item_counters">
                                                <span className="widget_instagram_images_item_counter_likes trx_addons_icon-heart">
                                                  43
                                                </span>
                                                <span className="widget_instagram_images_item_counter_comments trx_addons_icon-comment">
                                                  6
                                                </span>
                                              </span>
                                            </a>
                                          </div>
                                          <div className="widget_instagram_images_item_wrap trx_addons_inline_47016044">
                                            <a
                                              href="//spin.axiomthemes.com/wp-content/uploads/2020/05/post60-copyright-370x370.jpg"
                                              title=""
                                              rel="magnific"
                                              className="widget_instagram_images_item widget_instagram_images_item_type_image"
                                            >
                                              <img
                                                loading="lazy"
                                                decoding="async"
                                                src="//spin.axiomthemes.com/wp-content/uploads/2020/05/post60-copyright-370x370.jpg"
                                                width={370}
                                                height={370}
                                                alt=""
                                              />
                                              <span className="widget_instagram_images_item_counters">
                                                <span className="widget_instagram_images_item_counter_likes trx_addons_icon-heart">
                                                  67
                                                </span>
                                                <span className="widget_instagram_images_item_counter_comments trx_addons_icon-comment">
                                                  8
                                                </span>
                                              </span>
                                            </a>
                                          </div>
                                          <div className="widget_instagram_images_item_wrap trx_addons_inline_338139498">
                                            <a
                                              href="//spin.axiomthemes.com/wp-content/uploads/2023/09/post15-copyright-370x370.jpg"
                                              title=""
                                              rel="magnific"
                                              className="widget_instagram_images_item widget_instagram_images_item_type_image"
                                            >
                                              <img
                                                loading="lazy"
                                                decoding="async"
                                                src="//spin.axiomthemes.com/wp-content/uploads/2023/09/post15-copyright-370x370.jpg"
                                                width={370}
                                                height={370}
                                                alt=""
                                              />
                                              <span className="widget_instagram_images_item_counters">
                                                <span className="widget_instagram_images_item_counter_likes trx_addons_icon-heart">
                                                  94
                                                </span>
                                                <span className="widget_instagram_images_item_counter_comments trx_addons_icon-comment">
                                                  41
                                                </span>
                                              </span>
                                            </a>
                                          </div>
                                          <div className="widget_instagram_images_item_wrap trx_addons_inline_936793227">
                                            <a
                                              href="//spin.axiomthemes.com/wp-content/uploads/2023/09/about-4-copyright-370x370.jpg"
                                              title=""
                                              rel="magnific"
                                              className="widget_instagram_images_item widget_instagram_images_item_type_image"
                                            >
                                              <img
                                                loading="lazy"
                                                decoding="async"
                                                src="//spin.axiomthemes.com/wp-content/uploads/2023/09/about-4-copyright-370x370.jpg"
                                                width={370}
                                                height={370}
                                                alt=""
                                              />
                                              <span className="widget_instagram_images_item_counters">
                                                <span className="widget_instagram_images_item_counter_likes trx_addons_icon-heart">
                                                  86
                                                </span>
                                                <span className="widget_instagram_images_item_counter_comments trx_addons_icon-comment">
                                                  97
                                                </span>
                                              </span>
                                            </a>
                                          </div>
                                        </div>
                                        <div className="widget_instagram_follow_link_wrap">
                                          <a
                                            href="https://www.instagram.com/axiom_themes/"
                                            className="widget_instagram_follow_link sc_button"
                                            target="_blank"
                                          >
                                            Follow Me
                                          </a>
                                        </div>
                                      </div>
                                    </aside>
                                  </div>{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                  {/* .entry-content */}
                </article>
              </div>
            </div>
          </div>
          <a
            id="footer_skip_link_anchor"
            className="spin_skip_link_anchor"
            href="#"
          />
          <footer className="footer_wrap footer_custom footer_custom_8832 footer_custom_footer-professional-club-spin						 scheme_light						">
            <div
              data-elementor-type="cpt_layouts"
              data-elementor-id={8832}
              className="elementor elementor-8832"
            >
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-ce69137 elementor-section-content-middle sc_layouts_row sc_layouts_row_type_compact elementor-reverse-mobile elementor-reverse-tablet elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                data-id="ce69137"
                data-element_type="section"
              >
                <div className="elementor-container elementor-column-gap-extended">
                  <div
                    className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-5f21df9 sc-tablet_layouts_column_align_center sc_layouts_column sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                    data-id="5f21df9"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="sc_layouts_item elementor-element elementor-element-547b918 sc_fly_static elementor-widget elementor-widget-text-editor"
                        data-id="547b918"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div className="elementor-widget-container">
                          © 2024 by{" "}
                          <a
                            href="https://themeforest.net/user/axiomthemes/portfolio"
                            target="_blank"
                            rel="noopener"
                          >
                            AxiomThemes
                          </a>
                          .{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-bb1498b sc_layouts_column_align_center sc_layouts_column sc-mobile_layouts_column_align_center sc_layouts_column sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                    data-id="bb1498b"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="sc_layouts_item elementor-element elementor-element-2713c52 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_menu"
                        data-id="2713c52"
                        data-element_type="widget"
                        data-widget_type="trx_sc_layouts_menu.default"
                      >
                        <div className="elementor-widget-container">
                          <nav
                            className="sc_layouts_menu sc_layouts_menu_default sc_layouts_menu_dir_horizontal menu_hover_zoom_line"
                            data-animation-in="none"
                            data-animation-out="none"
                          >
                            <ul
                              id="sc_layouts_menu_1593324209"
                              className="sc_layouts_menu_nav"
                            >
                              <li
                                id="menu-item-26257"
                                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-26257"
                              >
                                <a href="/">
                                  <span>Home</span>
                                </a>
                              </li>
                              <li
                                id="menu-item-25953"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-25953"
                              >
                                <a href="/about">
                                  <span>About</span>
                                </a>
                              </li>
                              <li
                                id="menu-item-28034"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-28034"
                              >
                                <a href="/policy">
                                  <span>Policy</span>
                                </a>
                              </li>
                              <li
                                id="menu-item-26084"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-26084"
                              >
                                <a href="/terms">
                                  <span>Terms</span>
                                </a>
                              </li>
                            </ul>
                          </nav>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-c17642e sc_layouts_column_align_right sc_layouts_column sc-tablet_layouts_column_align_center sc_layouts_column sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                    data-id="c17642e"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="sc_layouts_item elementor-element elementor-element-099e0d5 sc_fly_static elementor-widget elementor-widget-trx_sc_socials"
                        data-id="099e0d5"
                        data-element_type="widget"
                        data-widget_type="trx_sc_socials.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="sc_socials modern_2 sc_socials_default sc_align_none">
                            <div className="socials_wrap">
                              <a
                                target="_blank"
                                href="https://www.facebook.com/AxiomThemes-505060569826537/"
                                className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"
                              >
                                <span
                                  className="social_icon social_icon_facebook-1"
                                  style={{}}
                                >
                                  <span className="icon-facebook-1" />
                                </span>
                              </a>
                              <a
                                target="_blank"
                                href="https://twitter.com/ThemesAxiom/"
                                className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"
                              >
                                <span
                                  className="social_icon social_icon_twitter-new"
                                  style={{}}
                                >
                                  <span className="icon-twitter-new" />
                                </span>
                              </a>
                              <a
                                target="_blank"
                                href="https://dribbble.com/axiomthemes/"
                                className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"
                              >
                                <span
                                  className="social_icon social_icon_dribble-new"
                                  style={{}}
                                >
                                  <span className="icon-dribble-new" />
                                </span>
                              </a>
                              <a
                                target="_blank"
                                href="https://www.instagram.com/axiom_themes/"
                                className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"
                              >
                                <span
                                  className="social_icon social_icon_instagram"
                                  style={{}}
                                >
                                  <span className="icon-instagram" />
                                </span>
                              </a>
                            </div>
                            {/* /.socials_wrap */}
                          </div>
                          {/* /.sc_socials */}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </footer>
          {/* /.footer_wrap */}
        </div>
      </div>
      <div className="sc_layouts_panel_hide_content" />
      <div
        id="popup-1"
        className="sc_layouts scheme_dark sc_layouts_panel sc_layouts_4509 sc_layouts_panel_right sc_layouts_effect_slide trx_addons_inline_721324205"
        data-delay={0}
        data-panel-position="right"
        data-panel-effect="slide"
        data-panel-class="trx_addons_inline_721324205"
      >
        <div className="sc_layouts_panel_inner">
          {" "}
          <div
            data-elementor-type="cpt_layouts"
            data-elementor-id={4509}
            className="elementor elementor-4509"
          >
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-67b4187 elementor-section-height-full elementor-section-items-stretch elementor-section-content-space-between elementor-section-boxed elementor-section-height-default sc_fly_static"
              data-id="67b4187"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-extended">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-56dc68b6 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                  data-id="56dc68b6"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-1c135e79 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_logo"
                      data-id="1c135e79"
                      data-element_type="widget"
                      data-widget_type="trx_sc_layouts_logo.default"
                    >
                      <div className="elementor-widget-container">
                        <a
                          href="#"
                          className="sc_layouts_logo sc_layouts_logo_default trx_addons_inline_948538618"
                        >
                          <img
                            loading="lazy"
                            className="logo_image"
                            src="//spin.axiomthemes.com/wp-content/uploads/2023/07/new-logo.png"
                            srcSet="//spin.axiomthemes.com/wp-content/uploads/2023/07/new-logo-2x.png 2x"
                            alt="Spin"
                            width={108}
                            height={80}
                          />
                        </a>{" "}
                      </div>
                    </div>
                    <section
                      className="elementor-section elementor-inner-section elementor-element elementor-element-9641a64 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                      data-id="9641a64"
                      data-element_type="section"
                    >
                      <div className="elementor-container elementor-column-gap-extended">
                        <div
                          className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-7f1146e sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                          data-id="7f1146e"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-38be0cc sc_fly_static elementor-widget elementor-widget-heading"
                              data-id="38be0cc"
                              data-element_type="widget"
                              data-widget_type="heading.default"
                            >
                              <div className="elementor-widget-container">
                                <h6 className="elementor-heading-title elementor-size-default">
                                  Have a project?
                                </h6>{" "}
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-03d84fa sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="03d84fa"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner" />
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-433eecc elementor-widget__width-auto has-text-dark-color sc_fly_static elementor-widget elementor-widget-heading"
                              data-id="433eecc"
                              data-element_type="widget"
                              data-widget_type="heading.default"
                            >
                              <div className="elementor-widget-container">
                                <h5 className="elementor-heading-title elementor-size-default">
                                  <a href="/cdn-cgi/l/email-protection#fa93949c95ba9f979b9396d4999597">
                                    <span
                                      className="__cf_email__"
                                      data-cfemail="4920272f26092c24282025672a2624"
                                    >
                                      [email&nbsp;protected]
                                    </span>
                                  </a>
                                </h5>{" "}
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-5b745d2 sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="5b745d2"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner" />
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-0e42aa4 sc_fly_static elementor-widget elementor-widget-heading"
                              data-id="0e42aa4"
                              data-element_type="widget"
                              data-widget_type="heading.default"
                            >
                              <div className="elementor-widget-container">
                                <h6 className="elementor-heading-title elementor-size-default">
                                  Want to join our team?
                                </h6>{" "}
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-81494a2 sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="81494a2"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner" />
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-03505c2 elementor-widget__width-auto has-text-dark-color sc_fly_static elementor-widget elementor-widget-heading"
                              data-id="03505c2"
                              data-element_type="widget"
                              data-widget_type="heading.default"
                            >
                              <div className="elementor-widget-container">
                                <h5 className="elementor-heading-title elementor-size-default">
                                  <a href="https://spin.axiomthemes.com/contacts/">
                                    Contact us{" "}
                                    <span
                                      className="icon-path-6190 has-text-link-color"
                                      style={{
                                        verticalAlign: "middle",
                                        fontSize: 9,
                                        fontWeight: 400,
                                        marginLeft: 22
                                      }}
                                    />
                                  </a>
                                </h5>{" "}
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-31ff93d sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="31ff93d"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner" />
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-8145589 sc_fly_static elementor-widget elementor-widget-heading"
                              data-id={8145589}
                              data-element_type="widget"
                              data-widget_type="heading.default"
                            >
                              <div className="elementor-widget-container">
                                <h6 className="elementor-heading-title elementor-size-default">
                                  Want to buy stuff?
                                </h6>{" "}
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-6eb97a9 sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="6eb97a9"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner" />
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-8d7dacf elementor-widget__width-auto has-text-dark-color sc_fly_static elementor-widget elementor-widget-heading"
                              data-id="8d7dacf"
                              data-element_type="widget"
                              data-widget_type="heading.default"
                            >
                              <div className="elementor-widget-container">
                                <h5 className="elementor-heading-title elementor-size-default">
                                  <a href="https://spin.axiomthemes.com/shop/">
                                    Go to store{" "}
                                    <span
                                      className="icon-path-6190 has-text-link-color"
                                      style={{
                                        verticalAlign: "middle",
                                        fontSize: 9,
                                        fontWeight: 400,
                                        marginLeft: 22
                                      }}
                                    />
                                  </a>
                                </h5>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <div
                      className="elementor-element elementor-element-d4736d3 sc_fly_static elementor-widget elementor-widget-spacer"
                      data-id="d4736d3"
                      data-element_type="widget"
                      data-widget_type="spacer.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-spacer">
                          <div className="elementor-spacer-inner" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <a href="#" className="sc_layouts_panel_close trx_addons_button_close">
            <span className="sc_layouts_panel_close_icon trx_addons_button_close_icon" />
          </a>
        </div>
      </div>
      <div
        id="go-video"
        className="sc_layouts sc_layouts_popup sc_layouts_12962"
        data-delay={0}
      >
        {" "}
        <div
          data-elementor-type="cpt_layouts"
          data-elementor-id={12962}
          className="elementor elementor-12962"
        >
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-cf4e536 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
            data-id="cf4e536"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-no">
              <div
                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e45dd90 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                data-id="e45dd90"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-eddc457 sc_fly_static elementor-widget elementor-widget-trx_widget_video"
                    data-id="eddc457"
                    data-element_type="widget"
                    data-widget_type="trx_widget_video.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="widget_area sc_widget_video">
                        <aside className="widget widget_video">
                          <div
                            id="sc_video_1754746339"
                            className="trx_addons_video_player without_cover"
                          >
                            <div className="video_embed video_frame">
                              <iframe
                                allow="autoplay"
                                data-src="https://player.vimeo.com/video/239539114?autoplay=1"
                                width={1290}
                                height={726}
                              />
                            </div>
                          </div>
                        </aside>
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <a
        href="#"
        className="trx_addons_scroll_to_top trx_addons_icon-up scroll_to_top_style_default"
        title="Scroll to top"
      />{" "}
    </body>

  )
}

export default Home